import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Navbar from "../components/Nav.jsx";
import Chart from "chart.js";
import Spinner from "../../../src/Spinner.svg";
import constant from "../../utils/constants";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { overHeadConsumables } from "atoms/General/index.js";
import socketIOClient from "socket.io-client";
import { toast, ToastContainer } from "react-toastify";
import constants from "../../utils/constants";
import TCPPGraph from "../TCPPGraph.jsx";
import BCPPGraph from "../components/BCPPGraph.jsx";
import ProductionScenario from "app/components/ProductionScenario/index.jsx";
import ChartDataLabels from "chartjs-plugin-datalabels";
import HeatMap from "react-heatmap-grid";
import HeatMapPdfGenerator from "../components/HeatMapPdfGenerator";
import DognutPdfGenerator from "../components/DognutPdfGenerator";
import SensitivityPdfGenerator from "../SensitivityPdfGenerator";
import * as htmlToImage from "html-to-image";
import ReactTooltip from "react-tooltip";
import GenerateReportPopUp from "../components/GenerateReportPopUp.jsx";
import previewReportPdf from "../../utils/previewReportPdf";
import { Spinner as SpinnerRS } from "reactstrap";
import { useAccess } from "state/hooks.js";
import gql from "graphql-tag";
import { AiFillCloseCircle, AiOutlineEye } from "react-icons/ai";
import Menu from "@mui/material/Menu";
import { useHistory, useParams } from "react-router-dom";
import { CostData, ButtonRenderState } from "atoms/CostToCarbonData/index.js";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { VisualDonut } from "atoms/General/index.js";
import { Modal, Button } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import config from "../../config";
import { TotalJobs, SingleJobTime, WorkingHours, VisualPageReload,ProductionScheduleImage } from "atoms/General/index.js";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy.jsx";
import { Technology_Name } from "utils/constantenums";
const axios = require("axios").default;
const SAVE_CLIENT_CALCULATIONS = gql`
  mutation saveClientCalculations($calculations: Object) {
    saveClientCalculations(calculations: $calculations)
  }
`;

const Analytics = ({ ScenarioState, socket }) => {
  const { savedlist } = useParams();
  // Save analytics list
  // console.log("svaedlist", savedlist);
  let Access = useAccess();
  const history = useHistory();
  const [, setCostData] = useRecoilState(CostData);
  const [, setRenderButton] = useRecoilState(ButtonRenderState);
  // const [graphRender, setGraphRender] = useRecoilState(GraphRenderCheck)

  const [, setVisualDonut] = useRecoilState(VisualDonut);
  const [reload, setReload] = useRecoilState(VisualPageReload)
  // const calculatorType = useRecoilValue(CalculatorType)


  const workingHours = useRecoilValue(WorkingHours)



  const [toggleMutation] = useMutation(SAVE_CLIENT_CALCULATIONS);

  let technology_name = sessionStorage.getItem("technology");
  let [tcppObj, setTcppObj] = useState({});
  let [sensitivityGraphObj, setSensitivityGraphObj] = useState({});
  let [formFields, setFormFields] = useState({});
  let [analyticsName, setAnalyticsName] = useState("");
  const [analyticsShow, setAnalyticsShow] = useState(false);

  const handleCloseAnalyticsNameModal = () => setAnalyticsShow(false);
  const handleShowAnalyticsNameModal = () => setAnalyticsShow(true);
  const [scenarioModal, setScenarioModal] = useState(false)
  const [scenarioCount, setScenarioCount] = useState([constant.DEFAULT_SCENARIO].length)

  const handleClosescenarioModal = () => setScenarioModal(false);
  const handleShowscenarioModal = () => {
    setScenarioModal(true);

  }

  let [technologyId, setTechnologyId] = useState(-1);
  let [selectedScenarioName, setSelectedScenarioName] = useState(
    constant.DEFAULT_SCENARIO
  );
  let [scenarios, setScenarios] = useState([constant.DEFAULT_SCENARIO]);
  let [technologyName, setTechnologyName] = useState("");
  let [stepFields] = useState([]);
  let [techSteps, setTechSteps] = useState([]);
  let [socketObj, setSocketObj] = useState(socket && socket);
  let [fieldsLoader] = useState(false);
  let [tempHetrogeneousJobs, setTempHetrogeneousJobs] = useState([]);
  let [sensitivityLoader, setSensitivityLoader] = useState(false);
  let [cppLoader, setCppLoader] = useState(false);
  let [matrixLoader, setMatrixLoader] = useState(false);
  let [calculatorLoader, setCalculatorLoader] = useState(false);
  let [machine_options, setMachineOptions] = useState([]);
  let [material_options, setMaterialtOptions] = useState([]);
  let [parameters_options, setParametersOptions] = useState([]);
  let [fill_rate_options, setFillRateOptions] = useState([]);
  let [filter_options, setFilterOptions] = useState([]);
  let [recoater_blade_options, setRecoaterBladeOptions] = useState([]);
  let [inert_gas_options, setInertGasOptions] = useState([]);
  let [build_platform_options, setBuildPlatformOptions] = useState([]);
  let [targetValue, setTargetValue] = useState(0);
  let [sensitivityObj, setSensitivityObj] = useState({});
  let [matrixX, setMatrixX] = useState({});
  let [matrixY, setMatrixY] = useState({});
  let [matrixFromKey, setMatrixFromKey] = useState("");
  let [matrixToKey, setMatrixToKey] = useState("");
  let [matrixData, setMatrixData] = useState({
    fromX: [],
    fromY: [],
    data: [],
  });
  let [sensitivityLegends, setSensitivityLegends] = useState([]);
  let [psData, setpsData] = useState();

  let [currency, setCurrency] = useState("");
  let [dognutChartsUrls, setDognutChartsUrls] = useState([]);
  let [dognutChartsUrlsTwo, setDognutChartsUrlsTwo] = useState([]);
  let [sensitivityChartUrls, setSensitivityChartUrls] = useState([]);
  let [dognutDownloadPdf, setDognutDownloadPdf] = useState(false);
  let [sensitivityDownloadPdf, setSensitivityDownloadPdf] = useState(false);
  let [heatMap, setHeatMap] = useState();
  let [heatMapDownloadPdf, setHeatMapDownloadPdf] = useState();

  const [totalJobs, setTotalJobs] = useRecoilState(TotalJobs);
  const [singleJobTime, setSingleJobTime] = useRecoilState(SingleJobTime);
  const [, setPsImage] = useRecoilState(ProductionScheduleImage);

  const [workingWeeksPerYear, setWorkingWeeksPerYear] = useState(46);
  const jobYear = workingWeeksPerYear * totalJobs;

  const client = useApolloClient();
  const [isOpen, setIsOpen] = useState(false);
  const [reportLoader, setReportloader] = useState(false);
  const [formHolder, setFormHolder] = useState("form-holder-1");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [headconsumables, setHeadConsumables] =
    useRecoilState(overHeadConsumables);
  const useRenderButton = useRecoilValue(ButtonRenderState);
  const [metalCo2ePerPart, setMetalCo2ePerPart] = useState(0);
  const [psCheck, setPsCheck] = useState(1)
  const [scenarioRemoved, setScenarioRemoved] = useState(false)
  //Geting currency symbol
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const curr = sessionStorage.getItem("currency");
  const regionObj = currencies.find((x) => x.name === curr);

  const calculatorType = sessionStorage.getItem("calculatorType");
  const gettingMachinesObj = sessionStorage.getItem("machines")
  const getMaterialAvailbleForCarbon = sessionStorage.getItem("materials")
  // const result = gettingMachinesObj.find((item) => console.log("Item", item))


  const isEnbaleCarbonButton = gettingMachinesObj === "true" && getMaterialAvailbleForCarbon === "true"


  useEffect(() => {
    setScenarioCount(scenarios.length)
  }, [scenarios.length])




  useEffect(() => {

    if (reload) {
      window.location.reload()
      setReload(false)
    }
    if (history.location.pathname === "/calculator/visual-analytics/true") {
      sessionStorage.removeItem("Carbon");
      window.localStorage.setItem("metalCo2pp", 0)
      window.scrollTo(0, 0)
    }

    if (
      sessionStorage.getItem("Carbon") !== undefined &&
      Number(sessionStorage.getItem("Carbon")) === 1
    ) {
      setRenderButton(false);
      setMetalCo2ePerPart(window.localStorage.getItem("metalCo2pp"));
      setAnchorEl(null);
    } else {
      if (
        history.location.pathname === "/calculator/visual-analytics/true" ||
        (Number(sessionStorage.getItem("Carbon")) === 0 &&
          history.location.pathname === "/calculator/visual-analytics/false")
      ) {
        setRenderButton(true);
        window.localStorage.setItem("metalCo2pp", 0);
        window.localStorage.setItem("costOneKGCo2", 0);
        sessionStorage.setItem("Carbon", 0);
      } else {

        setRenderButton(false);
        setMetalCo2ePerPart(window.localStorage.getItem("metalCo2pp"));
        setAnchorEl(null);
        sessionStorage.setItem("Carbon", 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getjobsWeek = (jobs) => {
    setTotalJobs(jobs);
  };
  const getTurnAroundTime = (time) => {
    setSingleJobTime(time);
  };
  const handleCarbonCalculatorRoute = (event) => {
    let id = sessionStorage.getItem("stepTypeId");
    let technology = sessionStorage.getItem("technology");
    if (technology === "Metal") {
      if (Number(id) === 1) {
        // TODO...
        // console.log("Metal Estimation: ");
      } else if (Number(id) === 2) {
        event.preventDefault();
        socketObj.off("*");
        history.push("/carboncalculator/carbon-metal");
        setCostData({ ...formFields[selectedScenarioName] });
      }
    } else if (technology === "Polymer") {
      if (Number(id) === 1) {
        // TODO...
        // console.log("Metal Estimation:");
      } else if (Number(id) === 2) {
        event.preventDefault();
        socketObj.off("*");

        // console.log("Check Costdata", { ...formFields[selectedScenarioName] });
        window.localStorage.setItem("CostData", JSON.stringify({ ...formFields[selectedScenarioName] }));
        history.push("/carboncalculator/carbon-polymer");
        setCostData({ ...formFields[selectedScenarioName] });
      }
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    formHolder === "form-holder-2"
      ? setFormHolder("form-holder-1")
      : setFormHolder("form-holder-2");
  };

  const previewScenarioPdf = () => {
    handleClick();
    previewReportPdf(setReportloader, false, setFormHolder);
  };

  const getStepId = () => {
    if (technology_name === "Polymer") {
      return 2;
    } else {
      return 1;
    }
  };
  const access_token = sessionStorage.getItem("access_token");


  const SaveAnalytics = async () => {
    toast.info("Please Wait...");
    const fields = formFields;
    let allFields = {}
    if (Object.keys(formFields)?.length > 1) {
      const keys = Object.keys(formFields);
      for (let i = 0; i < keys.length; i++) {
        if (i !== 0) {
          allFields[keys?.[i]] = { ...formFields?.[keys[i]] }
        }
      }
    }
    const analyticsFormFields = {
      formFields: fields,
      otherScenarios: JSON.stringify(allFields),
      technology_name: sessionStorage.getItem("technologyName"),
      technology: sessionStorage.getItem("technology"),
      currencies: JSON.parse(sessionStorage.getItem("all_currencies")),
      currency: sessionStorage.getItem("currency"),
      CPPOC: localStorage.getItem("CPP OC"),
      metalCo2pp:
        localStorage.getItem("metalCo2pp") !== undefined
          ? localStorage.getItem("metalCo2pp")
          : 0,
      stepsAndFields: JSON.parse(sessionStorage.getItem("stepsAndFields")),
      group_id: sessionStorage.getItem("group_id"),
      signInstatus: sessionStorage.getItem("signInstatus"),
      costOneKGCo2:
        localStorage.getItem("costOneKGCo2") !== undefined
          ? localStorage.getItem("costOneKGCo2")
          : 0,
      Carbon: sessionStorage.getItem("Carbon"),
    };
    const data = {
      technology_id: fields?.technology_id?.value,
      step_type_id: fields?.step_type_id?.value,
      material_id: fields?.material_id?.value,
      machine_id: fields?.machine_id?.value,
      analytic_data: JSON.stringify(analyticsFormFields),
      analytic_name: analyticsName,
      calculator_type: calculatorType
    };
    // Data for analytics...
    // console.log("dataforanalytics", data);
    try {
      const response = await axios.post(
        `${config.serviceUrl}client/analytics/save`,
        data,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );

      if (response.data) {
        toast.success("Visual Analytics Saved Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (socket) {
      setSocketObj(socket);
    } else {
      const socket1 = socketIOClient(constant.uri);
      const patch = require("socketio-wildcard")(socketIOClient.Manager);
      patch(socket1);
      setSocketObj(socket1);
    }
    const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
    const currency = sessionStorage.getItem("currency");
    const obj3 = currencies.find((x) => x.name === currency);
    setCurrency(obj3.currency_symbol);
    setHeadConsumables(window.localStorage.getItem("CPP OC"));

    if (sessionStorage.getItem("technologyName") === "Metal (Estimation)") {
      let obj = sensitivityLegends;
      obj = [
        { value: "UTILIZATION", key: "utilization", unit: "hours/year" },
        {
          value: "POST PROCESSING COST PER PART",
          key: "post_processing_cost_per_part",
          unit: obj3.currency_symbol,
        },
        { value: "BUILD RATE", key: "build_rate", unit: "cm3/h" },
        {
          value: "MATERIAL PRICE",
          key: "material_price",
          unit: `${obj3.currency_symbol}/kg`,
        },
        { value: "PART VOLUME", key: "part_volume", unit: "cm3" },
      ];
      setSensitivityLegends([...obj]);
    } else if (
      sessionStorage.getItem("technologyName") === "Metal (Simulation)"
    ) {
      let obj = sensitivityLegends;
      obj =
        history.location.pathname !== "/calculator/visual-analytics/true"
          ? [
            { value: "UTILIZATION", key: "utilization", unit: "hours/year" },
            {
              value: "POST PROCESSING COST PER PART",
              key: "post_processing_cost_per_part",
              unit: obj3.currency_symbol,
            },
            { value: "BUILD TIME", key: "build_time", unit: "h" },
            {
              value: "MATERIAL PRICE",
              key: "material_price",
              unit: `${obj3.currency_symbol}/kg`,
            },
            { value: "PART VOLUME", key: "part_volume", unit: "cm3" },
            // Revert Carbon Pricing Grpah Changes...  TODO

            /*  {
                value: "Carbon Pricing",
                key: "cost_of_producing_one_kg_co2e",
                unit: `${obj3.currency_symbol}`,
              },
              */
          ]
          : [
            { value: "UTILIZATION", key: "utilization", unit: "hours/year" },
            {
              value: "POST PROCESSING COST PER PART",
              key: "post_processing_cost_per_part",
              unit: obj3.currency_symbol,
            },
            { value: "BUILD TIME", key: "build_time", unit: "h" },
            {
              value: "MATERIAL PRICE",
              key: "material_price",
              unit: `${obj3.currency_symbol}/kg`,
            },
            { value: "PART VOLUME", key: "part_volume", unit: "cm3" },
          ];
      setSensitivityLegends([...obj]);
    } else if (
      sessionStorage.getItem("technologyName") === "Polymer (Estimation)"
    ) {
      let obj = sensitivityLegends;
      obj = [
        { value: "UTILIZATION", key: "utilization", unit: "hours/year" },
        {
          value: "POST PROCESSING COST PER PART",
          key: "post_processing_cost_per_part",
          unit: obj3.currency_symbol,
        },
        { value: "BUILD RATE", key: "build_rate", unit: "cm3/h" },
        {
          value: "MATERIAL PRICE",
          key: "material_price",
          unit: `${obj3.currency_symbol}/kg`,
        },
        { value: "REFRESHING RATE", key: "refreshing_rate", unit: "%" },
        { value: "BUILD HEIGHT", key: "build_height", unit: "mm" },
      ];
      setSensitivityLegends([...obj]);
    } else if (
      sessionStorage.getItem("technologyName") === "Polymer (Simulation)"
    ) {
      let obj = sensitivityLegends;
      obj = [
        { value: "UTILIZATION", key: "utilization", unit: "hours/year" },
        {
          value: "POST PROCESSING COST PER PART",
          key: "post_processing_cost_per_part",
          unit: obj3.currency_symbol,
        },
        { value: "BUILD TIME", key: "build_time", unit: "h" },
        {
          value: "MATERIAL PRICE",
          key: "material_price",
          unit: `${obj3.currency_symbol}/kg`,
        },
        { value: "REFRESHING RATE", key: "refreshing_rate", unit: "%" },
        { value: "BUILD HEIGHT", key: "build_height", unit: "mm" },

        // Revert Carbon Pricing Grpah Changes...  TODO

        /*  history.location.pathname !== "/calculator/visual-analytics/true"
            ? Number(sessionStorage.getItem("Carbon")) === 0
              ? { value: "BUILD HEIGHT", key: "build_height", unit: "mm" }
              : {
                value: "Carbon Pricing",
                key: "cost_of_producing_one_kg_co2e",
                unit: `${obj3.currency_symbol}`,
              }
            : { value: "BUILD HEIGHT", key: "build_height", unit: "mm" },
            */
      ];
      setSensitivityLegends([...obj]);
    }
    // sensitivity legends arrays....

    setSelectedScenarioName(constant.DEFAULT_SCENARIO);
    if (sessionStorage.getItem("calculator_values_multiple")) {
      const calculator_values_multiple = JSON.parse(sessionStorage.getItem("calculator_values_names"))
      if (calculator_values_multiple?.length) {
        for (const item of calculator_values_multiple) {
          let calculator_values_obj = JSON.parse(
            sessionStorage.getItem(`calculator_values_${item}`)
          );
          let calculator_values_array = formFields[item];
          if (!calculator_values_array) {
            calculator_values_array = {};
          }
          for (
            let index = 0;
            index < Object.keys(calculator_values_obj).length;
            index++
          ) {
            calculator_values_array[Object.keys(calculator_values_obj)[index]] =
              calculator_values_obj[Object.keys(calculator_values_obj)[index]];
          }
          let obj = formFields;
          obj[item] = calculator_values_array;
          setFormFields({ ...obj });
          setScenarios(calculator_values_multiple)
        }
      }
    }
    else {
      let calculator_values_obj = JSON.parse(
        sessionStorage.getItem("calculator_values_default")
      );
      let calculator_values_array = formFields[selectedScenarioName];
      if (!calculator_values_array) {
        calculator_values_array = {};
      }
      for (
        let index = 0;
        index < Object.keys(calculator_values_obj).length;
        index++
      ) {
        calculator_values_array[Object.keys(calculator_values_obj)[index]] =
          calculator_values_obj[Object.keys(calculator_values_obj)[index]];
      }
      let obj = formFields;
      obj[selectedScenarioName] = calculator_values_array;
      setFormFields({ ...obj });
    }

    var technology_id = getStepId();
    renderDognut();
    const stepsAndFields = JSON.parse(sessionStorage.getItem("stepsAndFields"));
    let techStepsObj = techSteps;
    techStepsObj = stepsAndFields;
    setTechSteps([...techStepsObj]);
    // Hetro Rendering Start
    // // debugger;
    if (techStepsObj[0]) {
      const calculatedFields = [];
      const traversalIndex = techStepsObj[0].fields.findIndex(
        (x) => x.field_input_type === "enable_multiple_rows"
      );
      if (
        formFields[constant.DEFAULT_SCENARIO] &&
        formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS] &&
        formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS].length !==
        0 &&
        formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS].length !==
        1
      )
        if (technologyName === "Polymer Simulation") {
          const limitIndex = traversalIndex - 2;
          for (let index = limitIndex; index < traversalIndex; index++) {
            calculatedFields.push(techStepsObj[0].fields[index]);
          }
          const array = [...tempHetrogeneousJobs, ...calculatedFields];
          setTempHetrogeneousJobs(array);
        } else {
          const limitIndex = traversalIndex - 3;
          for (let index = limitIndex; index < traversalIndex; index++) {
            calculatedFields.push(techStepsObj[0].fields[index]);
          }
          const array = [...tempHetrogeneousJobs, ...calculatedFields];
          setTempHetrogeneousJobs(array);
        }
    }
    // // Hetro Rendering End
    setTechnologyName(
      `${stepsAndFields[0].technology_id.name} ${stepsAndFields[0].step_type_id.name}`
    );
    setTechnologyId(technology_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDognut = () => {
    if (formFields && Object.keys(formFields).length) {
      if ((history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")) {

        Chart.defaults.global.defaultFontStyle = "Bold";

        let sum = 0
        let array = []
        let percentageDataSet = []

        for (let index = 0; index < scenarios.length; index++) {
          // console.log("first", window.localStorage.getItem("metalCo2pp"))

          array = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_cost_per_part"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_cost_per_part"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseInt(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_cost_per_part"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_cost_per_part"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["post_processing_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_cost_per_part"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "post_processing_cost_per_part"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_cost_per_part"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            // parseInt(
            //   (
            //     (!useRenderButton && window.localStorage.getItem("metalCo2pp") !== "0" &&
            //       formFields[scenarios[index]]["total_cost_per_part"] !== undefined
            //       ? parseFloat(
            //         window.localStorage.getItem("metalCo2pp")
            //       ) /
            //       parseFloat(
            //         formFields[scenarios[index]]["total_cost_per_part"].value
            //       )
            //       : 0) * 100
            //   ).toFixed(2)
            // ),
          ]
        }
        sum = array.reduce((preValue, currValue) => preValue + currValue, 0)

        array.forEach(val => percentageDataSet.push(+((val / sum) * 100).toFixed(2)))



        for (let index = 0; index < scenarios.length; index++) {
          var d1 = document.getElementById(`d1-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[index]) {
            window.dognutChart[index].destroy();
          }
          window.dognutChart[index] = new Chart(d1, {
            plugins: [ChartDataLabels],
            type: "doughnut",

            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSet
                    //TODO...
                    // [
                    //   parseFloat(
                    //     (
                    //       (formFields[scenarios[index]] &&
                    //         formFields[scenarios[index]][
                    //         "system_cost_per_part"
                    //         ] !== undefined &&
                    //         formFields[scenarios[index]][
                    //         "total_cost_per_part"
                    //         ] !== undefined
                    //         ? parseFloat(
                    //           formFields[scenarios[index]][
                    //             "system_cost_per_part"
                    //           ].value
                    //         ) /
                    //         parseFloat(
                    //           formFields[scenarios[index]][
                    //             "total_cost_per_part"
                    //           ].value
                    //         )
                    //         : 0) * 100
                    //     ).toFixed(2)
                    //   ),
                    //   parseFloat(
                    //     (
                    //       (formFields[scenarios[index]] &&
                    //         formFields[scenarios[index]][
                    //         "material_cost_per_part"
                    //         ] !== undefined &&
                    //         formFields[scenarios[index]][
                    //         "total_cost_per_part"
                    //         ] !== undefined
                    //         ? parseFloat(
                    //           formFields[scenarios[index]][
                    //             "material_cost_per_part"
                    //           ].value
                    //         ) /
                    //         parseFloat(
                    //           formFields[scenarios[index]][
                    //             "total_cost_per_part"
                    //           ].value
                    //         )
                    //         : 0) * 100
                    //     ).toFixed(2)
                    //   ),
                    //   parseFloat(
                    //     (
                    //       (formFields[scenarios[index]] &&
                    //         formFields[scenarios[index]][
                    //         "post_processing_cost_per_part"
                    //         ] !== undefined &&
                    //         formFields[scenarios[index]][
                    //         "total_cost_per_part"
                    //         ] !== undefined
                    //         ? parseFloat(
                    //           formFields[scenarios[index]][
                    //             "post_processing_cost_per_part"
                    //           ].value
                    //         ) /
                    //         parseFloat(
                    //           formFields[scenarios[index]][
                    //             "total_cost_per_part"
                    //           ].value
                    //         )
                    //         : 0) * 100
                    //     ).toFixed(2)
                    //   ),
                    //   // parseInt(
                    //   //   (
                    //   //     ((!useRenderButton && window.localStorage.getItem("metalCo2pp") !== "0"
                    //   //       ) &&
                    //   //       formFields[scenarios[index]]["total_cost_per_part"] !== undefined
                    //   //       ? parseFloat(
                    //   //         window.localStorage.getItem("metalCo2pp")
                    //   //       ) /
                    //   //       parseFloat(
                    //   //         formFields[scenarios[index]]["total_cost_per_part"].value
                    //   //       )
                    //   //       : 0) * 100
                    //   //   ).toFixed(2)
                    //   // )
                    // ]
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#9ea6b4",]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                "System",
                "Material",
                "Post Processing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                labels: {
                  fontColor: "#c9cbcd",
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 600,
                  fontSize: 10,
                  padding: 30,
                },
              },
              tooltips: {
                enabled: true,
              },
              hover: { mode: null },
              plugins: {
                datalabels: false,

                // TODO...

                // {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(2) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
        }
      }
      else if (history.location.pathname === "/calculator/visual-analytics/true") {
        Chart.defaults.global.defaultFontStyle = "Bold";

        let sum = 0
        let sumTwo = 0
        let array = []
        let arrayTwo = []
        let percentageDataSet = []
        let percentageDataSetTwo = []
        for (let index = 0; index < scenarios.length; index++) {
          // console.log("first", window.localStorage.getItem("metalCo2pp"))
          array = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_consumables"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["cpp_consumables"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["post_processing_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "post_processing_cost_per_part"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_overhead"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "cpp_overhead"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
          arrayTwo = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["consumable_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["consumable_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
        }

        sum = array.reduce((preValue, currValue) => preValue + currValue, 0)
        sumTwo = arrayTwo.reduce((preValue, currValue) => preValue + currValue, 0)

        array.forEach(val => percentageDataSet.push(+((val / sum) * 100).toFixed(2)))
        arrayTwo.forEach(val => percentageDataSetTwo.push(+((val / sumTwo) * 100).toFixed(2)))
        for (let index = 0; index < scenarios.length; index++) {
          var d1 = document.getElementById(`d1-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index] = new Chart(d1, {
            plugins: [ChartDataLabels],
            type: "doughnut",

            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSet
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a',]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                " Post Processing",
                " Overhead",
                " Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },


              hover: { mode: null },
              plugins: {
                datalabels: false,
                // TODO
                // {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
          var d2 = document.getElementById(`d2-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1] = new Chart(d2, {
            plugins: [ChartDataLabels],
            type: "doughnut",

            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSetTwo
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a',]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                " Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },


              hover: { mode: null },
              plugins: {
                datalabels: false,
                // TODO
                // {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
        }

      } else if (Number(sessionStorage.getItem("Carbon")) === 0) {
        Chart.defaults.global.defaultFontStyle = "Bold";
        let sum = 0
        let sumTwo = 0
        let array = []
        let arrayTwo = []
        let percentageDataSet = []
        let percentageDataSetTwo = []
        for (let index = 0; index < scenarios.length; index++) {
          // console.log("first", window.localStorage.getItem("metalCo2pp"))
          array = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_consumables"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["cpp_consumables"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["post_processing_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "post_processing_cost_per_part"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_overhead"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "cpp_overhead"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
          arrayTwo = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["consumable_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["consumable_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
        }

        sum = array.reduce((preValue, currValue) => preValue + currValue, 0)
        sumTwo = arrayTwo.reduce((preValue, currValue) => preValue + currValue, 0)

        array.forEach(val => percentageDataSet.push(+((val / sum) * 100).toFixed(2)))
        arrayTwo.forEach(val => percentageDataSetTwo.push(+((val / sumTwo) * 100).toFixed(2)))
        for (let index = 0; index < scenarios.length; index++) {
          var d1 = document.getElementById(`d1-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index] = new Chart(d1, {
            plugins: [ChartDataLabels],
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSet
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a',]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                " Post Processing",
                " Overhead",
                " Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },
              hover: { mode: null },
              plugins: {
                datalabels: false,
                // TODO
                // {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
          var d2 = document.getElementById(`d2-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1] = new Chart(d2, {
            plugins: [ChartDataLabels],
            type: "doughnut",

            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSetTwo
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_am_cost_cm3"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_am_cost_cm3"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_am_cost_cm3"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_am_cost_cm3"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a',]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                " Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },
              hover: { mode: null },
              plugins: {
                datalabels: false,
                // TODO
                // {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
        }
      }
      else {

        Chart.defaults.global.defaultFontStyle = "Bold";
        let sum = 0
        let sumTwo = 0
        let array = []
        let arrayTwo = []
        let percentageDataSet = []
        let percentageDataSetTwo = []

        for (let index = 0; index < scenarios.length; index++) {
          // console.log("first", window.localStorage.getItem("metalCo2pp"))
          array = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_part"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_consumables"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["cpp_consumables"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["post_processing_cost_per_part"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "post_processing_cost_per_part"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["cpp_overhead"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]][
                      "cpp_overhead"
                    ].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseInt(
              (
                (!useRenderButton &&
                  window.localStorage.getItem("metalCo2pp") !== "0" &&
                  formFields[scenarios[index]]["total_am_cpp"] !== undefined
                  ? parseFloat(window.localStorage.getItem("metalCo2pp")) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cpp"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
          arrayTwo = [
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["system_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["system_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["recurring_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["recurring_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["material_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["material_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseFloat(
              (
                (formFields[scenarios[index]] &&
                  formFields[scenarios[index]]["consumable_cost_per_cm3"] !==
                  undefined &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(
                    formFields[scenarios[index]]["consumable_cost_per_cm3"].value
                  ) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
            parseInt(
              (
                (!useRenderButton &&
                  window.localStorage.getItem("metalCo2pp") !== "0" &&
                  formFields[scenarios[index]]["total_am_cost_cm3"] !== undefined
                  ? parseFloat(window.localStorage.getItem("metalCo2pp")) /
                  parseFloat(
                    formFields[scenarios[index]]["total_am_cost_cm3"].value
                  )
                  : 0) * 100
              ).toFixed(2)
            ),
          ]
        }
        sum = array.reduce((preValue, currValue) => preValue + currValue, 0)
        sumTwo = arrayTwo.reduce((preValue, currValue) => preValue + currValue, 0)
        array.forEach(val => percentageDataSet.push(+((val / sum) * 100).toFixed(2)))
        arrayTwo.forEach(val => percentageDataSetTwo.push(+((val / sumTwo) * 100).toFixed(2)))


        for (let index = 0; index < scenarios.length; index++) {
          var d1 = document.getElementById(`d1-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index) : index] = new Chart(d1, {
            plugins: [ChartDataLabels],
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSet
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a', "#00bff3"]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                "Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },
              hover: { mode: null },
              plugins: {
                datalabels: false
                // TODO

                // datalabels: {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
          var d2 = document.getElementById(`d2-${scenarios[index]}-${index}`);
          if (!window.dognutChart) {
            window.dognutChart = [];
          }
          if (window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1]) {
            window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1].destroy();
          }
          window.dognutChart[(scenarios?.length > 1 && index > 0) ? (index + index + 1) : index + 1] = new Chart(d2, {
            plugins: [ChartDataLabels],
            type: "doughnut",

            data: {
              datasets: [
                {
                  data: Access
                    ?
                    percentageDataSetTwo
                    : [
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "system_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "system_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                      parseFloat(
                        (
                          (formFields[scenarios[index]] &&
                            formFields[scenarios[index]][
                            "material_cost_per_part"
                            ] !== undefined &&
                            formFields[scenarios[index]][
                            "total_cost_per_part"
                            ] !== undefined
                            ? parseFloat(
                              formFields[scenarios[index]][
                                "material_cost_per_part"
                              ].value
                            ) /
                            parseFloat(
                              formFields[scenarios[index]][
                                "total_cost_per_part"
                              ].value
                            )
                            : 0) * 100
                        ).toFixed(2)
                      ),
                    ],
                  backgroundColor: Access
                    ? ["#556679", "#7daebc", "#777777", "#0095c8", "#00677d", '#4a596a', "#00bff3"]
                    : ["#556679", "#00bff3"],
                  borderWidth: 0,
                },
              ],
              borderWidth: 0,
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: [
                " System",
                "Recuring",
                " Material",
                'Consumables',
                "Carbon Pricing",
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              rotation: -1.5 * Math.PI,
              circumference: 2 * Math.PI,
              legend: {
                display: false,
                borderWidth: 0,
                position: "bottom",
                // labels: {
                //   fontColor: "#c9cbcd",
                //   fontFamily: "'Montserrat', sans-serif",
                //   fontWeight: 600,
                //   fontSize: 10,
                //   padding: 30,
                // },
              },
              tooltips: {
                enabled: true,
              },
              hover: { mode: null },
              plugins: {
                datalabels: false
                // TODO

                // datalabels: {
                //   formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     console.log("Dognut", value);
                //     // eslint-disable-next-line
                //     dataArr.map((data) => {
                //       sum += data;
                //     });
                //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
                //     console.log("Dognut Percentage", percentage);
                //     return percentage;
                //   },
                //   color: "#ffffff",
                //   font: {
                //     family: "Open Sans",
                //     size: 18,
                //     weight: 400,
                //   },
                // },
              },
            },
          });
        }

      }
      setTimeout(() => {
        let donut
        if (sessionStorage.getItem("technologyName") === Technology_Name?.METAL_ESTIMATION || sessionStorage.getItem("technologyName") === Technology_Name?.POLYMER_ESTIMATION) {
          donut = document.querySelector(".donutImageOne");
        }
        else {
          donut = document.querySelector(".donutImage");
        }
        if (donut) {
          setVisualDonut(donut.toDataURL("image/png"));
        }
      }, 2000);
    }
  };

  const renderMatrixSocket = () => {
    setMatrixLoader(true);
    let objX = matrixX;
    objX = {
      from:
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) -
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) *
        0.5,
      to:
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) +
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) *
        0.5,
      key: "utilization",
    };
    setMatrixX({ ...objX });

    setMatrixFromKey(objX.key);

    let objY = matrixY;
    objY = {
      from:
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) -
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) *
        0.5,
      to:
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) +
        parseFloat(formFields[constant.DEFAULT_SCENARIO]["utilization"].value) *
        0.5,
      key: "utilization",
    };
    setMatrixY({ ...objY });

    setMatrixToKey(objY.key);

    socketObj.emit("matrix-calculator", {
      rangeX: { ...objX },
      rangeY: { ...objY },
      ...JSON.parse(sessionStorage.getItem("calculator_values_default")),
    });
    socketObj.on("matrix-calculator-result", (data) => {
      setMatrixLoader(false);

      let mData = matrixData;
      mData["fromX"] = [...new Set(data.data.result.map((x) => x.fromX))];
      mData["fromY"] = [...new Set(data.data.result.map((y) => y.fromY))];
      let outer_data = [];
      for (let index = 0; index < mData["fromX"].length; index++) {
        let innerData = [];
        for (let j_index = 0; j_index < mData["fromY"].length; j_index++) {
          const obj = data.data.result.find(
            (x) =>
              x.fromX === mData["fromX"][index] &&
              x.fromY === mData["fromY"][j_index]
          );
          if (obj) {
            innerData.push(obj.value);
          }
        }
        outer_data.push(innerData);
      }
      //console.log(outer_data)
      for (var i = 0; i < outer_data.length; i++) {
        for (var j = 0; j < i; j++) {
          const tmp = outer_data[i][j];
          outer_data[i][j] = outer_data[j][i];
          outer_data[j][i] = tmp;
        }
      }
      //console.log(outer_data);
      mData["data"] = outer_data;
      setMatrixData({ ...mData });

      setTimeout(() => {
        var node = document.getElementById("heatmap");

        htmlToImage
          .toPng(node)
          .then(function (dataUrl) {
            setHeatMap(dataUrl);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      }, 1000);
    });
  };

  const sendMatrixValues = () => {
    setMatrixLoader(true);
    matrixX["from"] = parseFloat(matrixX["from"]);
    matrixX["to"] = parseFloat(matrixX["to"]);
    matrixY["from"] = parseFloat(matrixY["from"]);
    matrixY["to"] = parseFloat(matrixY["to"]);
    socketObj.emit("matrix-calculator", {
      rangeX: { ...matrixX },
      rangeY: { ...matrixY },
      ...JSON.parse(sessionStorage.getItem("calculator_values_default")),
    });
    socketObj.on("matrix-calculator-result", (data) => {
      setMatrixLoader(false);
      let mData = matrixData;
      mData["fromX"] = [...new Set(data.data.result.map((x) => x.fromX))];
      mData["fromY"] = [...new Set(data.data.result.map((y) => y.fromY))];
      let outer_data = [];

      for (let index = 0; index < mData["fromX"].length; index++) {
        let innerData = [];
        for (let j_index = 0; j_index < mData["fromY"].length; j_index++) {
          const obj = data.data.result.find(
            (x) =>
              x.fromX === mData["fromX"][index] &&
              x.fromY === mData["fromY"][j_index]
          );
          if (obj) {
            innerData.push(obj.value);
          }
        }
        outer_data.push(innerData);
      }
      for (var i = 0; i < outer_data.length; i++) {
        for (var j = 0; j < i; j++) {
          const tmp = outer_data[i][j];
          outer_data[i][j] = outer_data[j][i];
          outer_data[j][i] = tmp;
        }
      }
      mData["data"] = outer_data;
      setMatrixData({ ...mData });
    });
  };

  const getMatrixValues = (e, type, type_range) => {
    let objX = matrixX;
    objX = {
      from:
        type === "x-axis" && type_range === "from"
          ? e.target.value
          : matrixX["from"],
      to:
        type === "x-axis" && type_range === "to"
          ? e.target.value
          : matrixX["to"],
      key: matrixFromKey,
    };
    setMatrixX({ ...objX });

    let objY = matrixY;
    objY = {
      from:
        type === "y-axis" && type_range === "from"
          ? e.target.value
          : matrixY["from"],
      to:
        type === "y-axis" && type_range === "to"
          ? e.target.value
          : matrixY["to"],
      key: matrixToKey,
    };
    setMatrixY({ ...objY });
  };

  const renderBigGraphSocket = () => {

    setCppLoader(true);
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 20 },
      key: { value: "utilization" },
    });
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 10 },
      key: { value: "utilization" },
    });
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 20 },
      key: { value: "material_price" },
    });
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 10 },
      key: { value: "material_price" },
    });
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 20 },
      key: { value: "post_processing_cost_per_part" },
    });
    socketObj.emit("graph-calculator", {
      ...formFields[constant.DEFAULT_SCENARIO],
      percentage: { value: 10 },
      key: { value: "post_processing_cost_per_part" },
    });
    if (sessionStorage.getItem("technology") === "Metal") {
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 20 },
        key: { value: "part_volume" },
      });
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 10 },
        key: { value: "part_volume" },
      });
    } else {
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 20 },
        key: { value: "refreshing_rate" },
      });
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 10 },
        key: { value: "refreshing_rate" },
      });
    }
    if (technologyName.includes("Polymer Simulation")) {
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 20 },
        key: { value: "build_time" },
      });
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 10 },
        key: { value: "build_time" },
      });
    } else {
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 20 },
        key: { value: "build_rate" },
      });
      socketObj.emit("graph-calculator", {
        ...formFields[constant.DEFAULT_SCENARIO],
        percentage: { value: 10 },
        key: { value: "build_rate" },
      });
    }
    let loaderCounter = 0;
    socketObj.on("graph-calculator-result", (data) => {

      loaderCounter += 1;
      if (loaderCounter === 6) {
        setCppLoader(false);
      }
      const obj = tcppObj;
      console.log(
        `${data.data.key}-${data.data.percentage}`,
        data.data.total_cost_per_part
      );
      obj[`${data.data.key}-${data.data.percentage}`] =
        data.data.total_cost_per_part;
      setTcppObj({ ...obj });
    });
  };

  const executeRenderBigGraphSocket = (val) => {
    if (val) {
      renderBigGraphSocket();
    }
  };
  const getSensitivityValues = (event, key, state) => {
    socketObj.off("variable-graph-result");
    let obj = sensitivityObj;
    if (!obj[key]) {
      obj[key] = {
        from: 0,
        to: 0,
      };
    }
    obj[key][state] = parseFloat(event.target.value);
    setSensitivityObj({ ...obj });
    if (obj[key].from && obj[key].to && obj[key].to > obj[key].from) {
      const values = JSON.parse(sessionStorage.getItem("calculator_values_default"))
      const totalcost = values["total_cost_per_part"]?.value + +headconsumables
      if (technology_name === "Polymer") {
        values["cost_per_part"] = { value: totalcost, override: "" }
      }
      socketObj.emit("variable-graph-calculator", {
        range: {
          from: sensitivityObj[key]["from"],
          to: sensitivityObj[key]["to"],
        },
        key: { value: key },
        ...values,
      });
    }
    socketObj.on("variable-graph-result", (data) => {
      let obj = sensitivityGraphObj;
      obj[data.data.key] = data.data.result;
      setSensitivityGraphObj({ ...sensitivityGraphObj });
      renderCharts();
    });
    // ****************************************************\


    // **********************************************************************
  };

  const getSensitivitySocketValues = () => {

    setSensitivityLoader(true);
    for (let index = 0; index < sensitivityLegends.length; index++) {
      let obj = sensitivityObj;
      obj = {
        from:
          formFields[constant.DEFAULT_SCENARIO][
          sensitivityLegends[index].key
          ] &&
          parseFloat(
            formFields[constant.DEFAULT_SCENARIO][sensitivityLegends[index].key]
              .value
          ) -
          parseFloat(
            formFields[constant.DEFAULT_SCENARIO][
              sensitivityLegends[index].key
            ].value
          ) *
          0.5,
        to:
          formFields[constant.DEFAULT_SCENARIO][
          sensitivityLegends[index].key
          ] &&
          parseFloat(
            formFields[constant.DEFAULT_SCENARIO][sensitivityLegends[index].key]
              .value
          ) +
          parseFloat(
            formFields[constant.DEFAULT_SCENARIO][
              sensitivityLegends[index].key
            ].value
          ) *
          0.5,
      };

      setSensitivityObj({ ...obj });
      const values = formFields[constant.DEFAULT_SCENARIO];
      const totalcost = (+values["total_cost_per_part"]?.value + +values["cpp_overhead_consumables"]?.value).toFixed(2)

      if (technology_name === "Polymer") {
        values["cost_per_part"] = { value: totalcost, override: "" }
      }
      socketObj.emit("variable-graph-calculator", {
        range: { ...obj },
        key: { value: sensitivityLegends[index].key },
        ...values,
      });
    }
    let countIndex = 0;
    socketObj.on("variable-graph-result", (data) => {

      countIndex += 1;
      if (countIndex === 5 || countIndex === 6) {
        setSensitivityLoader(false);
      }
      let obj = sensitivityGraphObj;
      obj[data.data.key] = data.data.result;
      setSensitivityGraphObj({ ...sensitivityGraphObj });
      renderCharts();
    });

    // ****************************************************\


    // **********************************************************************
  };

  const renderCharts = () => {
    for (let index = 0; index < sensitivityLegends.length; index++) {
      const legend = sensitivityLegends[index];
      // TODO... part Volume graph
      var ml1 = document.getElementById(`${sensitivityLegends[index].key}-ml1`);
      if (sensitivityGraphObj[legend.key]) {
        if (!window.myChart) {
          window.myChart = [];
        }
        if (window.myChart[index]) {
          window.myChart[index].destroy();
        }
        Object.keys(sensitivityGraphObj[legend.key])
          .sort()
          .forEach(function (key) {
            sensitivityGraphObj[legend.key][key] =
              sensitivityGraphObj[legend.key][key];
          });

        let xAxisLabels = Object.keys(sensitivityGraphObj[legend.key]).map(
          (value) => {
            return parseFloat(parseFloat(value).toFixed(2));
          }
        );
        let yAxisValues = Object.values(sensitivityGraphObj[legend.key]).map(
          (value) => {
            return value ? parseFloat(value.toFixed(2)) : 0;
          }
        );
        xAxisLabels = xAxisLabels.sort((a, b) => {
          return a - b;
        });
        if (legend.key === "utilization" || legend.key === "build_rate") {
          yAxisValues = yAxisValues.sort((a, b) => {
            return b - a;
          });
        } else {
          yAxisValues = yAxisValues.sort((a, b) => {
            return a - b;
          });
        }

        window.myChart[index] = new Chart(ml1, {
          type: "line",
          data: {
            labels: xAxisLabels,

            datasets: [
              {
                lineTension: 0,
                data: yAxisValues,
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderColor: "#00677d",
                borderWidth: 4,
                pointRadius: 6,
                pointBackgroundColor: "#00677d",
                pointBorderColor: "#00677d",
              },
            ],
          },
          options: {
            title: {
              display: true,
              text: `${index + 1}. ${legend.value}`,
              fontFamily: "Open Sans",
              fontWeight: 700,
              fontSize: 14,
              fontColor: "#4d4d4d",
            },
            hover: () => {
              return;
            },
            bezierCurve: false,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: `${legend.value}`,
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    min: 0,
                    max: 10000,
                    pointLabels: {
                      fontStyle: "bold",
                    },
                    fontFamily: "Open Sans",

                    fontSize: 12,
                    fontColor: "#4d4d4d",
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "COST PER PART",
                  },
                  gridLines: {
                    color: "#DBD9DC",
                    display: false,
                  },
                  // ticks: {
                  //   maxTicksLimit: 6,

                  //   callback: function (value) {
                  //     return '$ ' + value + ".00";
                  //   },
                  //   min: 0,
                  //   max: 10,
                  //   display: true,
                  //   fontFamily: "Open Sans",

                  //   fontSize: 12,
                  //   fontColor: "#4d4d4d",
                  // },
                },
              ],
            },
            plugins: {
              datalabels: {
                display: false,
              },
            },
          },
        });
      }
    }
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/^[-+]?[0-9]+\.[0-9]+$/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const handleHighlight = (stepId) => {
    // Handling Dynamically Active States of Steps
    const divs = document.getElementsByClassName("menu-item");
    for (let index = 0; index < divs.length; index++) {
      divs[index].classList.remove("highlight");
    }

    for (let index = 0; index < stepFields.length; index++) {
      if (
        document.getElementById(`edit-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`edit-${stepFields[index].field_id}`)
          .classList.remove("hidden");
      }
      if (
        document.getElementById(`cross-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`cross-${stepFields[index].field_id}`)
          .classList.add("hidden");
      }
      if (
        document.getElementById(`div-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`div-${stepFields[index].field_id}`)
          .classList.add("hidden");
      }
      if (
        document.getElementById(`input-${stepFields[index].field_id}`) != null
      ) {
        document.getElementById(
          `input-${stepFields[index].field_id}`
        ).value = 0;
      }
    }

    document.getElementById(stepId).classList.add("highlight");
    setTechnologyName(
      `${techSteps[0].technology_id.name} ${techSteps[0].step_type_id.name}`
    );
  };

  const openEditBox = (fieldId, flag) => {
    if (flag) {
      document.getElementById(`edit-${fieldId}`).classList.add("hidden");
      document.getElementById(`cross-${fieldId}`).classList.remove("hidden");
      document.getElementById(`div-${fieldId}`).classList.remove("hidden");
    } else {
      document.getElementById(`edit-${fieldId}`).classList.remove("hidden");
      document.getElementById(`cross-${fieldId}`).classList.add("hidden");
      document.getElementById(`div-${fieldId}`).classList.add("hidden");
      const fieldObj = stepFields.find((x) => x.field_id === fieldId);
      if (fieldObj) {
        if (formFields[selectedScenarioName][fieldObj.keys]) {
          let array = formFields[selectedScenarioName];
          array[fieldObj.keys].override = false;
          let obj = formFields;
          obj[selectedScenarioName] = array;
          setFormFields({ ...formFields, ...obj });
          getFieldValues(null, false);
        }
      }
    }
  };

  const renderRetroJobs = () => {
    let tempHetrogeneousJobsModified = tempHetrogeneousJobs
    if (technologyName === "Polymer Simulation") {
      tempHetrogeneousJobsModified = tempHetrogeneousJobs.slice(1)
    }
    if (formFields[constant.DEFAULT_SCENARIO] && formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS] && formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS].length) {
      let result = []
      for (let i = 1; i < formFields[constant.DEFAULT_SCENARIO][constant.HETROGENEOUS]?.length; i++) {
        const temp = tempHetrogeneousJobsModified.map((hetroJobs, index) => {
          return (
            <tr
              key={hetroJobs.name}
              className={i === 1 && index === 0 ? "upper-hr" : ""}
            >
              <td>
                <div className="field">
                  <p data-tip={hetroJobs.field_info}>{hetroJobs.name}</p>
                  {renderTooltip(hetroJobs)}
                </div>
              </td>
              <td></td>
              <td>
                <div data-tip={hetroJobs.field_info} className="vcenter">
                  <div className="table-inputbox">
                    <input
                      type="text"
                      className="login-input"
                      value={
                        formFields[constant.DEFAULT_SCENARIO][
                          constant.HETROGENEOUS
                        ] &&
                          formFields[constant.DEFAULT_SCENARIO][
                          constant.HETROGENEOUS
                          ][i] !== undefined
                          ? formFields[constant.DEFAULT_SCENARIO][
                          constant.HETROGENEOUS
                          ][i][hetroJobs.keys]
                          : 0
                      }
                      name={hetroJobs.keys}
                      id={i}
                      onChange={(e) => {
                        getFieldValues(e, true, true, "number", false, true);
                      }}
                    />{" "}
                    {renderSubscript(hetroJobs.field_unit)}
                  </div>
                </div>
              </td>
            </tr>
          );
        })
        result.push(temp)
      }
      return result
    }

  };

  const unRegisterSocketEvents = () => {
    socketObj.off("visual-analytics-result");
  };

  const renderOptions = (data_reference_type) => {
    if (data_reference_type === "Machine") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_MACHINES,
          variables: { technology_id: technologyId, calculator_type: calculatorType },
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientMachineByTechnology.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientMachineByTechnology[index].machine_id,
              name: res.data.getClientMachineByTechnology[index].name,
              data_for_cost: res.data.getClientMachineByTechnology[index].is_data_avail_for_cost,
              data_for_carbon: res.data.getClientMachineByTechnology[index].is_data_avail_for_carbon
            });
          }
          setMachineOptions(options_array);
        })
        .catch((error) => {
          if (
            error.graphQLErrors[0].extensions.exception.response.status === 401
          ) {
            toast.error(
              error.graphQLErrors[0].extensions.exception.response.message
            );
            window.location.href = "/";
          }
        });
      //
    } else if (data_reference_type === "Material") {
      // Getting Fields by Step Id
      let group_id = +sessionStorage.getItem("group_id");

      client
        .query({
          query: constant.GET_MATERIALS,
          variables: {
            machine_id: parseInt(
              formFields[selectedScenarioName]["machine_id"].value
            ),
            group_id,
            calculator_type: calculatorType,
            fetchPolicy: 'no-cache'
          },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientMaterialByMachineId.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientMaterialByMachineId[index].material_id,
              name: res.data.getClientMaterialByMachineId[index].name,
              material_data_for_cost: res.data.getClientMaterialByMachineId[index].is_data_avail_for_cost,
              material_data_for_carbon: res.data.getClientMaterialByMachineId[index].is_data_avail_for_carbon,
            });
          }
          setMaterialtOptions(options_array);
        })
        .catch((error) => {
          if (
            error.graphQLErrors[0].extensions.exception.response.status ===
            401 &&
            sessionStorage.getItem("signInstatus") === "true"
          ) {
            toast.error(
              error.graphQLErrors[0].extensions.exception.response.message
            );
            window.location.href = "/";
          }
        });
      //
    } else if (data_reference_type === "Parameter") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_PARAMETERS,
          variables: {
            machine_id: parseInt(
              formFields[selectedScenarioName]["machine_id"].value
            ),
            material_id: parseInt(
              formFields[selectedScenarioName]["material_id"].value
            ),
          },
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientParemeterByMMIds.length;
            index++
          ) {
            if (
              technologyName === "Metal Estimation" ||
              technologyName === "Metal Simulation" ||
              technologyName === "Polymer Simulation"
            ) {
              options_array.push({
                id: res.data.getClientParemeterByMMIds[index].paremeter_id,
                name: res.data.getClientParemeterByMMIds[index].name,
              });
            } else {
              options_array.push({
                id: res.data.getClientParemeterByMMIds[index].name,
                name: res.data.getClientParemeterByMMIds[index].name,
              });
            }
          }
          setParametersOptions(options_array);
        })
        .catch((error) => {
          if (
            error.graphQLErrors[0].extensions.exception.response.status ===
            401 &&
            sessionStorage.getItem("signInstatus") === "true"
          ) {
            toast.error(
              error.graphQLErrors[0].extensions.exception.response.message
            );
            window.location.href = "/";
          }
        });
      //
    } else if (data_reference_type === "Parameter.fill_rate_type") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_FILL_RATES,
          variables: {
            machine_id: parseInt(
              formFields[selectedScenarioName]["machine_id"].value
            ),
            material_id: parseInt(
              formFields[selectedScenarioName]["material_id"].value
            ),
            parameter_name:
              formFields[selectedScenarioName]["parameter_name"].value,
          },
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientFillRates.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientFillRates[index].paremeter_id,
              fill_rate_type: res.data.getClientFillRates[index].fill_rate_type,
            });
          }
          setFillRateOptions(options_array);
        })
        .catch((error) => {
          if (
            error.graphQLErrors[0].extensions.exception.response.status ===
            401 &&
            sessionStorage.getItem("signInstatus") === "true"
          ) {
            toast.error(
              error.graphQLErrors[0].extensions.exception.response.message
            );
            window.location.href = "/";
          }
        });
      //
    } else if (data_reference_type === "Filters") {
      if (formFields?.machine_id?.value) {  // Getting Fields by Step Id
        client
          .query({

            query: constant.GET_FILTERS,
            variables: { machine_id: formFields?.machine_id?.value },
            fetchPolicy: 'no-cache'
          })
          .then((res) => {
            const options_array = [];
            for (
              let index = 0;
              index < res.data.getClientFilters.length;
              index++
            ) {
              options_array.push({
                id: res.data.getClientFilters[index].filter_id,
                name: res.data.getClientFilters[index].name,
              });
            }
            setFilterOptions(options_array);
          })
          .catch((error) => {
            if (
              error.graphQLErrors[0].extensions.exception.response.status ===
              401 &&
              sessionStorage.getItem("signInstatus") === "true"
            ) {
              toast.error(
                error.graphQLErrors[0].extensions.exception.response.message
              );
              window.location.href = "/";
            }
          });
      }
      //
    } else if (data_reference_type === "Recoater_Blade") {
      if (formFields?.machine_id?.value) {  // Getting Fields by Step Id

        client
          .query({
            query: constant.GET_RECOATER_BLADES,
            variables: { machine_id: +formFields?.machine_id?.value },
            fetchPolicy: 'no-cache'
          })
          .then((res) => {
            const options_array = [];
            for (
              let index = 0;
              index < res.data.getClientRacoaterBlades.length;
              index++
            ) {
              options_array.push({
                id: res.data.getClientRacoaterBlades[index].racoater_blade_id,
                name: res.data.getClientRacoaterBlades[index].recoater_blade_name,
              });
            }
            setRecoaterBladeOptions(options_array);
          })
          .catch((error) => {
            if (
              error.graphQLErrors[0].extensions.exception.response.status ===
              401 &&
              sessionStorage.getItem("signInstatus") === "true"
            ) {
              toast.error(
                error.graphQLErrors[0].extensions.exception.response.message
              );
              window.location.href = "/";
            }
          });
      }
    } else if (data_reference_type === "Inert_Gas") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_INERT_GAS,
          variables: {},
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientInertGas.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientInertGas[index].gas_id,
              name: res.data.getClientInertGas[index].name,
            });
          }
          setInertGasOptions(options_array);
        })
        .catch((error) => {
          if (
            error.graphQLErrors[0].extensions.exception.response.status ===
            401 &&
            sessionStorage.getItem("signInstatus") === "true"
          ) {
            toast.error(
              error.graphQLErrors[0].extensions.exception.response.message
            );
            window.location.href = "/";
          }
        });
    } else if (data_reference_type === "Build_Platform") {
      // Getting Fields by Step Id
      if (formFields?.machine_id?.value) {
        client
          .query({
            query: constant.GET_BUILD_PLATFORM,
            variables: { machine_id: +formFields?.machine_id?.value },
            fetchPolicy: 'no-cache'
          })
          .then((res) => {
            const options_array = [];
            for (
              let index = 0;
              index < res.data.getClientBuildPlatforms.length;
              index++
            ) {
              options_array.push({
                id: res.data.getClientBuildPlatforms[index].build_platform_id,
                name: res.data.getClientBuildPlatforms[index].build_platform_name,
              });
            }
            setBuildPlatformOptions(options_array);
          })
          .catch((error) => {
            if (
              error.graphQLErrors[0].extensions.exception.response.status ===
              401 &&
              sessionStorage.getItem("signInstatus") === "true"
            ) {
              toast.error(
                error.graphQLErrors[0].extensions.exception.response.message
              );
              window.location.href = "/";
            }
          });
      }
    }
  };

  const numberWithCommas = (x) => {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getFieldValues = (
    e,
    flagForChange = true,
    retro = false,
    fieldType = "number",
    override = false,
    multipleparts = false
  ) => {
    unRegisterSocketEvents();
    if (flagForChange) {
      let { name, value } = e.target;
      if (name === "material_id") {
        let result = material_options?.find(material => material.id === +value)
        sessionStorage.setItem("materials", result?.material_data_for_carbon)
        // checkArray1.push({ name: name, value: value })
      }
      if (name === "machine_id") {
        let result = machine_options?.find(machine => machine.id === +value)
        sessionStorage.setItem("machines", result?.data_for_carbon)

      }
      if (fieldType === "number") {
        if (isNaN(value)) {
          e.preventDefault();
          return;
        }
      }
      if (retro) {
        const retroObjArray =
          formFields[selectedScenarioName][constant.HETROGENEOUS] === undefined
            ? []
            : formFields[selectedScenarioName][constant.HETROGENEOUS];
        let customRetroObj = retroObjArray.find((x) => x.id === e.target.id);
        if (customRetroObj) {
          customRetroObj["id"] = e.target.id;
          customRetroObj[e.target.name] = e.target.value;
          retroObjArray[retroObjArray.findIndex((x) => x.id === e.target.id)] =
            customRetroObj;
          if (name === "part_volume") {
            let total_part_volume = 0;
            for (let index = 0; index < retroObjArray.length; index++) {
              total_part_volume += retroObjArray[index].part_volume;
            }
            let array = formFields[selectedScenarioName];
            array["total_part_volume"] = {
              value: total_part_volume,
              override: false,
            };
            let obj = formFields;
            obj[selectedScenarioName] = array;
            setFormFields({ ...formFields, ...obj });
          } else if (name === "no_of_parts") {
            let total_no_of_parts = 0;
            for (let index = 0; index < retroObjArray.length; index++) {
              total_no_of_parts += retroObjArray[index].no_of_parts;
            }
            let array = formFields[selectedScenarioName];
            array["total_no_of_parts"] = {
              value: total_no_of_parts,
              override: false,
            };
            let obj = formFields;
            obj[selectedScenarioName] = array;
            setFormFields({ ...formFields, ...obj });
          }
        } else {
          customRetroObj = {};
          customRetroObj["id"] = e.target.id;
          customRetroObj[e.target.name] = e.target.value;
          retroObjArray.push(customRetroObj);
          if (name === "part_volume") {
            let array = formFields[selectedScenarioName];
            array["total_part_volume"] = {
              value: e.target.value,
              override: false,
            };
            let obj = formFields;
            obj[selectedScenarioName] = array;
            setFormFields({ ...formFields, ...obj });
          } else if (name === "no_of_parts") {
            let array = formFields[selectedScenarioName];
            array["total_no_of_parts"] = {
              value: e.target.value,
              override: false,
            };
            let obj = formFields;
            obj[selectedScenarioName] = array;
            setFormFields({ ...formFields, ...obj });
          }
        }
        let array = formFields[selectedScenarioName];
        array[constant.HETROGENEOUS] = retroObjArray;
        let obj = formFields;
        obj[selectedScenarioName] = array;
        setFormFields({ ...formFields, ...obj });
      }
      if (multipleparts) {
        return
      }
      if (override) {
        let array = formFields[selectedScenarioName];
        array[name] =
          name !== "part_name" &&
            name !== "company_name" &&
            name !== "parameter_name"
            ? { value: value, override: true }
            : { value, override: true };
        let obj = formFields;
        obj[selectedScenarioName] = array;
        setFormFields({ ...formFields, ...obj });
        if (sessionStorage.getItem('technologyName') === 'Metal (Estimation)') {
          let array = formFields;
          if (name === "no_of_parts") {
            array["total_no_of_parts"] = { value: value, override: false }
            setFormFields({ ...formFields, ...array });
          }
        }
      } else {
        let array = formFields[selectedScenarioName];
        array[name] =
          name !== "part_name" &&
            name !== "company_name" &&
            name !== "parameter_name"
            ? { value: value, override: false }
            : { value, override: false };
        if (name === "machine_id") {
          renderOptions("Material");
        }
        if (name === "material_id") {
          renderOptions("Parameter");
        }
        if (name === "parameter_name") {
          renderOptions("Parameter.fill_rate_type");
        }
        let obj = formFields;
        obj[selectedScenarioName] = array;
        setFormFields({ ...formFields, ...obj });
      }
    } else {
      setCalculatorLoader(true);
      registerSocketEvents();
      socketObj.emit("visual-analytics", {
        ...formFields[selectedScenarioName],
      });
    }
  };

  const emitSocketBtn = () => {
    setCalculatorLoader(true);
    registerSocketEvents();
    parsingFormValues();
    const estimationVerticle = (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
    if (formFields[selectedScenarioName] && !estimationVerticle) {
      const array = formFields[selectedScenarioName]
      const totalAmCpp = parseFloat(formFields[selectedScenarioName]["system_cost_per_part"].value ? parseFloat(formFields[selectedScenarioName]["system_cost_per_part"].value) : 0 + formFields[selectedScenarioName]["recurring_cost_per_part"].value ? parseFloat(formFields[selectedScenarioName]["recurring_cost_per_part"].value) : 0 + formFields[selectedScenarioName]["material_cost_per_part"].value ? parseFloat(formFields[selectedScenarioName]["material_cost_per_part"].value) : 0 + formFields[selectedScenarioName]["cpp_consumables"].value ? parseFloat(formFields[selectedScenarioName]["cpp_consumables"].value) : 0)
      array["total_am_cpp"] = { value: totalAmCpp, override: false }
      const totalAmCm3 = parseFloat(formFields[selectedScenarioName]["system_cost_per_cm3"].value ? parseFloat(formFields[selectedScenarioName]["system_cost_per_cm3"].value) : 0 + formFields[selectedScenarioName]["recurring_cost_per_cm3"].value ? parseFloat(formFields[selectedScenarioName]["recurring_cost_per_cm3"].value) : 0 + formFields[selectedScenarioName]["material_cost_per_cm3"].value ? parseFloat(formFields[selectedScenarioName]["material_cost_per_cm3"].value) : 0 + formFields[selectedScenarioName]["consumable_cost_per_cm3"].value ? parseFloat(formFields[selectedScenarioName]["consumable_cost_per_cm3"].value) : 0)
      array["total_am_cost_cm3"] = { value: totalAmCm3, override: false }
      setFormFields({ ...formFields[selectedScenarioName], ...array })
    }
    if (selectedScenarioName === constants.DEFAULT_SCENARIO) {
      sessionStorage.setItem(
        "calculator_values_default",
        JSON.stringify({ ...formFields[selectedScenarioName] })
      );
    }
    sessionStorage.setItem(
      `calculator_values_${selectedScenarioName}`,
      JSON.stringify({ ...formFields[selectedScenarioName] })
    );
    socketObj.emit("visual-analytics", { ...formFields[selectedScenarioName] });
    setPsCheck(
      (value) => {
        return (value + 1)
      }
    )
  };

  const parsingFormValues = () => {
    let array = formFields[selectedScenarioName];
    for (const iterator in array) {
      if (!isNaN(array[iterator].value) && array[iterator].value !== "") {
        if (array[iterator]?.is_customizable) {
          array[iterator] = {
            value: parseFloat(array[iterator].value),
            override: array[iterator].override,
            field_id: array[iterator]?.field_id,
            is_customizable: array[iterator]?.is_customizable,
          };
        }
        else {
          array[iterator] = {
            value: parseFloat(array[iterator].value),
            override: array[iterator].override,
          };
        }
      }
    }
    setFormFields({ ...formFields, ...array });
  };

  const registerSocketEvents = () => {
    if (socketObj) {
      socketObj.on("disconnect", () => {
        setCalculatorLoader(false);
      });
      socketObj.on("visual-analytics-result", (event, data) => {
        setCalculatorLoader(false);
        let obj = formFields;
        obj[selectedScenarioName] = event.data;
        const estimationVerticle = (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
        if (obj[selectedScenarioName] && !estimationVerticle) {
          const array = obj[selectedScenarioName]
          const totalAmCpp = parseFloat(parseFloat(obj[selectedScenarioName]["system_cost_per_part"].value) + parseFloat(obj[selectedScenarioName]["recurring_cost_per_part"].value) + parseFloat(obj[selectedScenarioName]["material_cost_per_part"].value) + parseFloat(obj[selectedScenarioName]["cpp_consumables"].value))
          array["total_am_cpp"] = { value: totalAmCpp, override: false }
          const totalAmCm3 = parseFloat(parseFloat(obj[selectedScenarioName]["system_cost_per_cm3"].value) + parseFloat(obj[selectedScenarioName]["recurring_cost_per_cm3"].value) + parseFloat(obj[selectedScenarioName]["material_cost_per_cm3"].value) + parseFloat(obj[selectedScenarioName]["consumable_cost_per_cm3"].value))
          array["total_am_cost_cm3"] = { value: totalAmCm3, override: false }
          sessionStorage.setItem(
            `calculator_values_${selectedScenarioName}`,
            JSON.stringify({ ...formFields[selectedScenarioName], ...array })
          );
          setFormFields({ ...formFields[selectedScenarioName], ...array })
        }
        setFormFields({ ...obj });
        renderDognut();
      });
    }
  };

  const renderSubscript = (unit) => {
    if (unit === "cm3") {
      return (
        <span className="subscript-span">
          <var>
            cm<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "mm") {
      return (
        <span className="subscript-span">
          <var>mm</var>
        </span>
      );
    } else if (unit === "currency") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return <span className="subscript-span">{obj.currency_symbol}</span>;
      } else {
        return <></>;
      }
    } else if (unit === "g/cm3") {
      return (
        <span className="subscript-span">
          <var>
            g/cm<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "year" || unit === "years") {
      return <span className="subscript-span">year</span>;
    } else if (unit === "%") {
      return <span className="subscript-span">%</span>;
    } else if (unit === "hours" || unit === "hour") {
      return <span className="subscript-span">hours</span>;
    } else if (unit === "hours/year") {
      return <span className="subscript-span">hours/year</span>;
    } else if (unit === "μm") {
      return <span className="subscript-span">μm</span>;
    } else if (unit === "mm/h") {
      return <span className="subscript-span">mm/h</span>;
    } else if (unit === "kg") {
      return <span className="subscript-span">kg</span>;
    } else if (unit === "m2") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>2</sup>
          </var>
        </span>
      );
    } else if (unit === "currency/m2") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/m<sup>2</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/kg") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/kg</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/kW") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/kW</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "kW") {
      return <span className="subscript-span">kW</span>;
    } else if (unit === "m3") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "m3/job") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>3</sup>/job
          </var>
        </span>
      );
    } else if (unit === "cm3/h") {
      return (
        <span className="subscript-span">
          <var>
            cm<sup>3</sup>/g
          </var>
        </span>
      );
    } else if (unit === "currency/m3") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/m<sup>3</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/cm3") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/cm<sup>3</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/year") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/year</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/hour" || unit === "currency/h") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/hr</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "h") {
      return <span className="subscript-span">h</span>;
    }
  };

  const renderMaterialDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {machine_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {machine_options.map((optionObj, index) => {

          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName] && formFields[selectedScenarioName]["machine_id"] &&
                optionObj.id ===
                parseInt(formFields[selectedScenarioName]["machine_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderMachineDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {material_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {material_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName] && formFields[selectedScenarioName]["material_id"] &&
                optionObj.id ===
                parseInt(
                  formFields[selectedScenarioName]["material_id"].value
                )
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderParameterDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {parameters_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {parameters_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["parameter_id"] &&
                optionObj.id ===
                parseInt(
                  formFields[selectedScenarioName]["parameter_id"].value
                )
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderFillRateDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {fill_rate_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {fill_rate_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["parameter_id"] &&
                optionObj.id ===
                formFields[selectedScenarioName]["parameter_id"].value
              }
            >
              {optionObj.fill_rate_type}
            </option>
          );
        })}
      </select>
    );
  };

  const renderFilterDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {filter_options.length === 0 ? renderOptions(field.data_reference) : ""}
        {filter_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["filter_id"] &&
                optionObj.id ===
                parseInt(formFields[selectedScenarioName]["filter_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderRecoaterBladesDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {recoater_blade_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {recoater_blade_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["racoater_blade_id"] &&
                optionObj.id ===
                parseInt(
                  formFields[selectedScenarioName]["racoater_blade_id"].value
                )
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderInertGasDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {inert_gas_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {inert_gas_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["gas_id"] &&
                optionObj.id ===
                parseInt(formFields[selectedScenarioName]["gas_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderBuildPlatformsDD = (field) => {
    // TODO
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {build_platform_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {build_platform_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields[selectedScenarioName]["build_platform_id"] &&
                optionObj.id ===
                parseInt(
                  formFields[selectedScenarioName]["build_platform_id"].value
                )
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderTooltip = (field) => {
    if (field.field_info !== "") {
      return (
        <div className="field tooltip-p">
          <img src="/assets/info.png" alt="" height="21" width="21" />
          <span className="tooltiptext">{field.field_info}</span>
        </div>
      );
    }
  };

  const renderLoader = (field) => {
    if (field.loader) {
      return (
        <img
          className="small-loader"
          src="/static/media/Spinner.2863af0a.svg"
          alt="loader"
          height="10%"
        />
      );
    } else {
      let value = 0;
      if (formFields[selectedScenarioName] && formFields[selectedScenarioName][field.keys] === undefined) {
        if (field.field_type === "constant") {
          let array = formFields[selectedScenarioName];
          array[field.keys] = {
            value: parseFloat(field.data_reference),
            override: false,
          };
          let obj = formFields;
          obj[selectedScenarioName] = array;
          setFormFields({ ...formFields, ...obj });
        }
      } else {
        value = formFields[selectedScenarioName] ? formFields[selectedScenarioName][field.keys].value : 0;
      }
      const subscript = renderSubscript(field.field_unit);
      if (field.field_unit.includes("currency")) {
        return (
          <p>
            {numberWithCommas(value)} {subscript}
          </p>
        );
      } else {
        return (
          <p>
            {value} {subscript}
          </p>
        );
      }
    }
  };

  const renderCalcLoader = () => {
    if (calculatorLoader) {
      return (
        <img
          className="small-loader"
          src="/static/media/Spinner.2863af0a.svg"
          alt="loader"
          height="10%"
          style={{ marginLeft: "40%" }}
        />
      );
    } else {
      return (
        <>
          {isOpen && (
            <img
              className="report-logo"
              alt="loader"
              src="/assets/logo.png"
            ></img>
          )}
          {reportLoader && (
            <img
              className="report-logo"
              alt="loader"
              src="/assets/logo.png"
            ></img>
          )}
          {!!techSteps && !!techSteps?.length && techSteps.map((currentStep, index) => {
            return (
              <div className="row p-0" id={currentStep.name.replace(/ /g, "_")}>
                <div className="col center">
                  <div className="calcform2">
                    <p className="t-2 nudge">{currentStep.name}</p>
                    <p className="s-1">
                      {currentStep.technology_id.name +
                        " " +
                        currentStep.step_type_id.name}
                    </p>

                    <table className="table ">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col"></th>
                          <th scope="col">Input</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          // eslint-disable-next-line
                          currentStep &&
                          currentStep?.fields !== undefined &&
                          currentStep?.fields?.length &&
                          currentStep.fields.map((field, index) => {
                            if (field.field_input_type === "dropdown") {
                              return (
                                <tr>
                                  <td>
                                    <div className="field">
                                      <p>{field.name}</p>
                                      {renderTooltip(field)}
                                    </div>
                                  </td>

                                  <td></td>
                                  <td>
                                    {
                                      !!field && formFields[selectedScenarioName] &&
                                      <>
                                        <div className="vcenter">
                                          {field.data_reference && field.data_reference === "Machine"
                                            ? renderMaterialDD(field)
                                            : ""}
                                          {field.data_reference === "Material"
                                            ? renderMachineDD(field)
                                            : ""}
                                          {field.data_reference === "Parameter" &&
                                            formFields[selectedScenarioName][
                                            "material_id"
                                            ] !== undefined
                                            ? renderParameterDD(field)
                                            : ""}
                                          {field.data_reference ===
                                            "Parameter.fill_rate_type" &&
                                            formFields[selectedScenarioName][
                                            "material_id"
                                            ] !== undefined &&
                                            formFields[selectedScenarioName][
                                            "parameter_name"
                                            ] !== undefined
                                            ? renderFillRateDD(field)
                                            : ""}
                                          {field.data_reference === "Filters"
                                            ? renderFilterDD(field)
                                            : ""}
                                          {field.data_reference ===
                                            "Recoater_Blade"
                                            ? renderRecoaterBladesDD(field)
                                            : ""}
                                          {field.data_reference === "Inert_Gas"
                                            ? renderInertGasDD(field)
                                            : ""}
                                          {field.data_reference ===
                                            "Build_Platform"
                                            ? renderBuildPlatformsDD(field)
                                            : ""}
                                        </div>
                                        <span
                                          id={`error-${field.keys}`}
                                          className="error-messages"
                                        >
                                          Please select {field.name}
                                        </span>
                                      </>
                                    }

                                  </td>
                                </tr>
                              );
                            } else if (field.field_input_type === "label") {
                              return (
                                <tr>
                                  <td>
                                    {field.name}
                                    {renderTooltip(field)}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {renderLoader(field)}
                                  </td>
                                  <td>
                                    <div className="vcenter">
                                      <div
                                        id={`div-${field.field_id}`}
                                        className="edit-div table-inputbox2 hidden"
                                      >
                                        <input
                                          id={`input-${field.field_id}`}
                                          type="text"
                                          value={
                                            formFields[selectedScenarioName] && formFields[selectedScenarioName][
                                              field.keys
                                            ] !== undefined &&
                                              formFields[selectedScenarioName][
                                                field.keys
                                              ].override === true
                                              ? formFields[
                                                selectedScenarioName
                                              ][field.keys].value
                                              : ""
                                          }
                                          className="login-input"
                                          defaultValue="0"
                                          name={field.keys}
                                          onChange={(e) => {
                                            getFieldValues(
                                              e,
                                              true,
                                              false,
                                              field.field_data_type,
                                              true
                                            );
                                          }}
                                        />
                                      </div>
                                      <img
                                        id={`edit-${field.field_id}`}
                                        alt=""
                                        src="/assets/edit.png"
                                        width="23"
                                        height="23"
                                        onClick={() => {
                                          openEditBox(field.field_id, true);
                                        }}
                                        className={
                                          field.is_override_enabled === false
                                            ? "hidden editimg"
                                            : "editimg"
                                        }
                                      />
                                      <img
                                        id={`cross-${field.field_id}`}
                                        alt=""
                                        src="/assets/cross.png"
                                        width="24"
                                        height="24"
                                        onClick={() => {
                                          openEditBox(field.field_id, false);
                                        }}
                                        className="hidden crossimg"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else if (
                              field.field_input_type === "multiple_labels"
                            ) {
                              return (
                                <tr>
                                  <td>
                                    {field.name}
                                    {renderTooltip(field)}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {renderLoader(field)}
                                  </td>
                                  <td>
                                    <div className="vcenter">
                                      <div
                                        id={`div-${field.field_id}`}
                                        className="edit-div table-inputbox2 hidden"
                                      >
                                        <input
                                          id={`input-${field.field_id}`}
                                          type="text"
                                          value={
                                            formFields[field.keys] !==
                                              undefined &&
                                              formFields[field.keys]
                                                .override === true
                                              ? formFields[field.keys].value
                                              : ""
                                          }
                                          className="login-input"
                                          defaultValue="0"
                                          name={field.keys}
                                          onChange={(e) => {
                                            getFieldValues(
                                              e,
                                              true,
                                              false,
                                              field.field_data_type,
                                              true
                                            );
                                          }}
                                        />
                                      </div>
                                      <img
                                        id={`edit-${field.field_id}`}
                                        alt=""
                                        src="/assets/edit.png"
                                        width="23"
                                        height="23"
                                        onClick={() => {
                                          openEditBox(field.field_id, true);
                                        }}
                                        className={
                                          field.is_override_enabled === false
                                            ? "hidden editimg"
                                            : "editimg"
                                        }
                                      />
                                      <img
                                        id={`cross-${field.field_id}`}
                                        alt=""
                                        src="/assets/cross.png"
                                        width="24"
                                        height="24"
                                        onClick={() => {
                                          openEditBox(field.field_id, false);
                                        }}
                                        className="hidden crossimg"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else if (
                              field.field_input_type === "textfield"
                            ) {
                              return (
                                <tr>
                                  <td>
                                    {field.name}
                                    {renderTooltip(field)}
                                  </td>

                                  <td></td>
                                  <td>
                                    <div
                                      data-tip={field.field_info}
                                      className="vcenter"
                                    >
                                      <div className="table-inputbox">
                                        <input
                                          type="text"
                                          className="login-input"
                                          name={field.keys}
                                          onKeyPress={onKeyPress}
                                          value={
                                            formFields[selectedScenarioName] && formFields[selectedScenarioName][
                                              field.keys
                                            ] === undefined
                                              ? ""
                                              : formFields[
                                              selectedScenarioName
                                              ] && formFields[
                                                selectedScenarioName
                                              ][field.keys].value
                                          }
                                          onChange={(e) => {
                                            field.keys === "part_volume" ||
                                              field.keys ===
                                              "part_support_volume" ||
                                              field.keys === "no_of_parts"
                                              ? getFieldValues(
                                                e,
                                                true,
                                                true,
                                                field.field_data_type
                                              )
                                              : getFieldValues(
                                                e,
                                                true,
                                                false,
                                                field.field_data_type
                                              );
                                          }}
                                        />
                                        {renderSubscript(field.field_unit)}
                                      </div>
                                    </div>
                                    <span
                                      id={`error-${field.keys}`}
                                      className="error-messages"
                                    >
                                      {field.name} is Required
                                    </span>
                                  </td>
                                </tr>
                              );
                            } else if (
                              field.field_input_type === "label_section"
                            ) {
                              return (
                                <tr className="hr">
                                  <td>
                                    <p className="heading-bolder">
                                      {field.name}
                                    </p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              );
                            } else if (
                              field.field_input_type ===
                              "enable_multiple_rows"
                            ) {
                              return (
                                <>
                                  {renderRetroJobs()}
                                  {/* <tr className="upper-hr">
                                      <td>
                                        <div id="add-more" onClick={() => addMoreJobs(technologyName, index)}>
                                          <img alt="" src="../assets/add.png" height="24" width="24" />
                                          <p>Add more for heterogeneous job</p>
                                        </div>
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr> */}
                                </>
                              );
                            }
                            else return null
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  const renderComponents = () => {
    return renderCalcLoader();
  };

  const renderFields = () => {
    if (fieldsLoader) {
      return (
        <>
          <div
            className="row pad-50 fill"
            id="map"
            style={{ marginTop: "15%" }}
          >
            <div className="col p-0 deadcenter ">
              <img
                className="mt-n5"
                src={Spinner}
                alt="loader"
                height="100px"
              />
            </div>
          </div>
        </>
      );
    } else {
      return <>{renderComponents()}</>;
    }
  };

  const addScenario = () => {
    if (scenarios.length === 4) {
      return;
    }
    let array = scenarios;
    array.push(document.getElementById("scenario-input").value);
    setScenarios([...array]);

    let obj = formFields;
    let innerObject = [];
    if (
      formFields[document.getElementById("scenario-input").value] === undefined
    ) {
      const keys = Object.keys(formFields[constants.DEFAULT_SCENARIO]);
      for (let z_index = 0; z_index < keys.length; z_index++) {
        innerObject[keys[z_index]] =
          formFields[constants.DEFAULT_SCENARIO][keys[z_index]];
      }
      obj[document.getElementById("scenario-input").value] = innerObject;
    } else {
      obj[document.getElementById("scenario-input").value] =
        formFields[document.getElementById("scenario-input").value];
    }
    setTimeout(() => {
      renderDognut();
    }, 250);
    setFormFields(obj);
    const calculator_values_names = Object.keys(obj)
    sessionStorage.setItem("calculator_values_names", JSON.stringify(calculator_values_names))
    let allFields = {}
    if (Object.keys(obj)?.length > 1) {
      const keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i++) {
        if (i !== 0) {
          allFields[keys?.[i]] = { ...obj?.[keys[i]] }
        }
      }
    }
    if (!!Object.keys(allFields)?.length) {
      for (const item of Object.keys(allFields)) {
        sessionStorage.setItem(
          `calculator_values_${item}`,
          JSON.stringify(allFields[item])
        );
      }
      sessionStorage.setItem(
        "calculator_values_multiple",
        JSON.stringify(allFields)
      );
    }
  };


  const removeScenario = (index) => {
    sessionStorage.removeItem(`calculator_values_${scenarios[index]}`)
    const formdata = formFields
    delete formdata[scenarios[index]]
    const calculator_values_names = Object.keys(formdata)
    sessionStorage.setItem("calculator_values_names", JSON.stringify(calculator_values_names))
    setFormFields(formdata)
    const tempScenarios = scenarios
    tempScenarios.splice(index, 1)
    setScenarios(tempScenarios)
    setSelectedScenarioName(constant.DEFAULT_SCENARIO);
    setScenarioRemoved(true)
  }

  const renderScenarios = () => {
    let divs = [];
    for (let index = 0; index < scenarios.length; index++) {
      divs.push(
        <div
          id={`${index}-scenario`}
          className={
            scenarios[index] === constant.DEFAULT_SCENARIO
              ? "scenario-tab-active scenario-tab"
              : "scenario-tab"
          }
          onClick={() => {
            setSelectedScenarioName(scenarios[index]);
            const classes = document.getElementsByClassName("scenario-tab");
            for (let j_index = 0; j_index < classes.length; j_index++) {
              classes[j_index].classList.remove("scenario-tab-active");
            }
            document
              .getElementById(`${index}-scenario`)
              .classList.add("scenario-tab-active");
          }}
        >
          <p>{scenarios[index]} {scenarios[index] !== constant.DEFAULT_SCENARIO && <img src="/assets/trashicon.svg" alt="" onClick={() => removeScenario(index)} />}</p>
        </div>
      );
    }
    if (scenarioRemoved) {
      document
        .getElementById(`${0}-scenario`)
        .classList.add("scenario-tab-active");
      setScenarioRemoved(false)
    }
    return divs;
  };

  const renderDownloadScenarioBtn = () => {
    if (scenarios.length > 1) {
      return (
        <div>
          {reportLoader && <SpinnerRS className="mr-1" size="sm" />}
          <button>
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </div>
      );
    } else {
      return ``;
    }
  };

  const renderCreateScenarioBtn = () => {
    if (scenarios.length < 4) {
      return (
        <div id="create-scenario" onClick={() => handleShowscenarioModal()}>
          <img src="/assets/add.png" height="24" width="24" alt="" />
          <p>Create Scenario</p>
        </div>
      );
    } else {
      return ``;
    }
  };

  const renderMatrixXAxisDD = () => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        value={matrixFromKey ? matrixFromKey : "utilization"}
        onChange={(e) => {
          let objX = matrixX;
          objX["key"] = e.target.value;
          setMatrixX({ ...objX });
          setMatrixFromKey(e.target.value);
        }}
      >
        <option value="utilization">Utilization</option>
        <option value="build_rate">Build Rate</option>
        <option value="material_price">Material Price</option>
        <option value="part_volume">Part Volume</option>
        <option value="initial_investment">Machine Price</option>
      </select>
    );
  };

  const renderMatrixYAxisDD = () => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        value={matrixToKey ? matrixToKey : "utilization"}
        onChange={(e) => {
          let objY = matrixY;
          objY["key"] = e.target.value;
          setMatrixY({ ...objY });
          setMatrixToKey(e.target.value);
        }}
      >
        <option value="utilization">Utilization</option>
        <option value="build_rate">Build Rate</option>
        <option value="material_price">Material Price</option>
        <option value="part_volume">Part Volume</option>
        <option value="initial_investment">Machine Price</option>
      </select>
    );
  };

  const renderMatrixLoader = () => {
    if (matrixLoader) {
      return (
        <img
          className="small-loader"
          src="/static/media/Spinner.2863af0a.svg"
          alt="loader"
          height="10%"
        />
      );
    } else {
      return (
        <>
          <div className="d-block">
            <table
              className="table single-row table-responsive-sm w-100"
              style={{ marginTop: "32px" }}
            >
              <thead>
                <th>X-Axis Legend</th>
                <th>X-Axis Range</th>
                <th>Target Price</th>
                <th>Y-Axis Legend</th>
                <th>Y-Axis Range</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="vcenter2 center">
                      {renderMatrixXAxisDD()}
                    </div>
                  </td>
                  <td>
                    <div className="vcenter2 center">
                      <p id="x-axis-p">
                        {matrixX
                          ? `${matrixX["from"]}-${matrixX["to"]}`
                          : 0 - 0}
                      </p>
                      <div
                        id={`x-axis-from-input`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="From"
                          value={matrixX ? matrixX["from"] : 0}
                          className="login-input f-14"
                          onChange={(e) => {
                            getMatrixValues(e, "x-axis", "from");
                          }}
                        />
                      </div>
                      <div
                        id={`x-axis-to-input2`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="To"
                          className="login-input f-14"
                          value={matrixX ? matrixX["to"] : 0}
                          onChange={(e) => {
                            getMatrixValues(e, "x-axis", "to");
                          }}
                        />
                      </div>
                      <img
                        id={`x-axis-edit`}
                        alt=""
                        src="/assets/edit.png"
                        width="18"
                        height="18"
                        className="editimg2"
                        onClick={() => {
                          document.getElementById(`x-axis-edit`).style.display =
                            "none";
                          document.getElementById(
                            `x-axis-cross`
                          ).style.display = "block";
                          document
                            .getElementById(`x-axis-from-input`)
                            .classList.remove("hidden");
                          document
                            .getElementById(`x-axis-to-input2`)
                            .classList.remove("hidden");
                          document.getElementById(`x-axis-p`).style.display =
                            "none";
                        }}
                      />
                      <img
                        id={`x-axis-cross`}
                        alt=""
                        src="/assets/cross.png"
                        width="20"
                        height="20"
                        className="hidden"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="vcenter">
                      <div className="table-inputbox" style={{ width: "100%" }}>
                        <input
                          type="text"
                          className="login-input"
                          value={targetValue}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              e.target.value !== undefined &&
                              e.target.value !== null
                            ) {
                              setTargetValue(e.target.value);
                            } else {
                              setTargetValue("");
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="vcenter2 center">
                      {renderMatrixYAxisDD()}
                    </div>
                  </td>
                  <td>
                    <div className="vcenter2 center">
                      <p id="y-axis-p">
                        {matrixY
                          ? `${matrixY["from"]}-${matrixY["to"]}`
                          : 0 - 0}
                      </p>
                      <div
                        id={`y-axis-from-input`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="From"
                          value={matrixY ? matrixY["from"] : 0}
                          className="login-input f-14"
                          onChange={(e) => {
                            getMatrixValues(e, "y-axis", "from");
                          }}
                        />
                      </div>
                      <div
                        id={`y-axis-to-input2`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="To"
                          className="login-input f-14"
                          value={matrixY ? matrixY["to"] : 0}
                          onChange={(e) => {
                            getMatrixValues(e, "y-axis", "to");
                          }}
                        />
                      </div>
                      <img
                        id={`y-axis-edit`}
                        alt=""
                        src="/assets/edit.png"
                        width="18"
                        height="18"
                        className="editimg2"
                        onClick={() => {
                          document.getElementById(`y-axis-edit`).style.display =
                            "none";
                          document.getElementById(
                            `y-axis-cross`
                          ).style.display = "block";
                          document
                            .getElementById(`y-axis-from-input`)
                            .classList.remove("hidden");
                          document
                            .getElementById(`y-axis-to-input2`)
                            .classList.remove("hidden");
                          document.getElementById(`y-axis-p`).style.display =
                            "none";
                        }}
                      />
                      <img
                        id={`y-axis-cross`}
                        alt=""
                        src="/assets/cross.png"
                        width="20"
                        height="20"
                        className="hidden"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      paddingLeft: "48%",
                      paddingTop: "1%",
                    }}
                  >
                    <button
                      onClick={() => {
                        sendMatrixValues();
                      }}
                      className="button3 myorder2-1"
                    >
                      Submit
                    </button>
                  </td>
                </tr>
                <tr>
                  <p
                    className="figure-title"
                    style={{
                      position: "absolute",
                      right: "14.5%",
                      top: "71%",
                      zIndex: 999999,
                    }}
                  >
                    <button
                      onClick={() => {
                        var node = document.getElementById("heatmap");

                        htmlToImage
                          .toPng(node)
                          .then(function (dataUrl) {
                            setHeatMap(dataUrl);
                            setHeatMapDownloadPdf(true);
                            setTimeout(() => {
                              setHeatMapDownloadPdf(false);
                            }, 2000);
                          })
                          .catch(function (error) {
                            console.error("oops, something went wrong!", error);
                          });
                      }}
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>{" "}
                  </p>
                </tr>
              </tbody>
            </table>
            <div className="row ">
              <div className="col-md-6 text-small right-divider p-5 d-flex align-items-center">
                <div>
                  <p>
                    When doing analysis, if it is not only one variable you
                    would like to change but rather more than one variable to
                    have an impact on cost per part. The matrix allows the
                    possibility to use two selected variables from the bar
                    above, define their range you want to have analysis on and
                    then check cost per part for the impact due to combination
                    of 02 variables.{" "}
                  </p>
                  <img
                    src="/assets/matrix-infographic.png"
                    alt=""
                    className="my-5 img-fluid"
                  />
                  <p>
                    For e.g. here you have the target price as 120 which would
                    be shown in yellow. Anything below this is a playing field
                    colored blue, target price above is colored red.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 d-flex flex-column align-items-center justify-content-center "
                id="heatmap"
              >
                <div className="d-flex align-items-center">
                  <HeatMap
                    xLabels={matrixData ? matrixData.fromX : []}
                    yLabels={matrixData ? matrixData.fromY : []}
                    xLabelsLocation={"bottom"}
                    xLabelsVisibility={[true, true, true, true, true, true]}
                    xLabelWidth={60}
                    yLabelWidth={60}
                    data={matrixData ? matrixData.data : []}
                    squares
                    height={70}
                    onClick={(x, y) => console.log(`Clicked ${x}, ${y}`)}
                    cellStyle={(background, value, min, max, data, x, y) => ({
                      background:
                        value === parseFloat(targetValue)
                          ? "#fcc342"
                          : value > targetValue
                            ? "#d33227"
                            : "#00bff3",
                      fontSize: "11.5px",
                      color: "#000",
                    })}
                    cellRender={(value) => value && <div>{value}</div>}
                  />
                  <div className="row">
                    <p
                      style={{
                        marginLeft: "100%",
                        top: "35%",
                        paddingBottom: "17px",
                        whiteSpace: "pre"

                      }}
                    >
                      {matrixY.key === "material_price"
                        ? "Material Price"
                        : matrixY.key === "build_rate"
                          ? "Build Rate"
                          : matrixY.key === "initial_investment"
                            ? "Machine Price"
                            : matrixY.key === "part_volume"
                              ? "Part Volume"
                              : "Utilization"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <p
                    style={{
                      paddingLeft: "30px",
                    }}
                  >
                    {matrixX.key === "material_price"
                      ? "Material Price"
                      : matrixX.key === "build_rate"
                        ? "Build Rate"
                        : matrixX.key === "initial_investment"
                          ? "Machine Price"
                          : matrixX.key === "part_volume"
                            ? "Part Volume"
                            : "Utilization"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const renderCppLoader = () => {
    if (cppLoader) {
      return (
        <img
          className="small-loader"
          src="/static/media/Spinner.2863af0a.svg"
          alt="loader"
          height="10%"
          style={{ marginLeft: "40%" }}
        />
      );
    } else {
      return (
        <TCPPGraph
          formFields={formFields}
          tcppObj={tcppObj}
          technologyName={technologyName}
        />
      );
    }
  };

  const renderSensitivityLoader = () => {
    if (sensitivityLoader) {
      return (
        <img
          className="small-loader"
          src="/static/media/Spinner.2863af0a.svg"
          alt="loader"
          height="10%"
          style={{ marginLeft: "40%" }}
        />
      );
    } else {
      return (
        <table className="table single-row table-responsive-sm">
          <thead>
            {sensitivityLegends.map((legend) => {
              return <th>{legend.value}</th>;
            })}
          </thead>
          <tbody>
            <tr>
              {sensitivityLegends.map((legend) => {
                return (
                  <td>
                    <div className="vcenter2 center">
                      <p id={`${legend.key}-p`}>
                        {formFields[constant.DEFAULT_SCENARIO] &&
                          formFields[constant.DEFAULT_SCENARIO][legend.key]
                          ? formFields[constant.DEFAULT_SCENARIO][legend.key]
                            .value
                          : 0}{" "}
                        {legend.unit}
                      </p>
                      <div
                        id={`${legend.key}-input`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="From"
                          value={
                            sensitivityObj[legend.key] !== undefined
                              ? sensitivityObj[legend.key]["from"]
                              : ""
                          }
                          className="login-input f-14"
                          onChange={(e) => {
                            getSensitivityValues(e, legend.key, "from");
                          }}
                        />
                      </div>
                      <div
                        id={`${legend.key}-input2`}
                        className="row table-inputbox3 hidden"
                      >
                        <input
                          type="number"
                          placeholder="To"
                          className="login-input f-14"
                          value={
                            sensitivityObj[legend.key] !== undefined
                              ? sensitivityObj[legend.key]["to"]
                              : ""
                          }
                          onChange={(e) => {
                            getSensitivityValues(e, legend.key, "to");
                          }}
                        />
                      </div>
                      <img
                        id={`${legend.key}-edit`}
                        alt=""
                        src="/assets/edit.png"
                        width="18"
                        height="18"
                        className="editimg2"
                        onClick={() => {
                          document.getElementById(
                            `${legend.key}-edit`
                          ).style.display = "none";
                          document.getElementById(
                            `${legend.key}-cross`
                          ).style.display = "block";
                          document
                            .getElementById(`${legend.key}-input`)
                            .classList.remove("hidden");
                          document
                            .getElementById(`${legend.key}-input2`)
                            .classList.remove("hidden");
                          document.getElementById(
                            `${legend.key}-p`
                          ).style.display = "none";
                        }}
                      />
                      <img
                        id={`${legend.key}-cross`}
                        alt=""
                        src="/assets/cross.png"
                        width="20"
                        height="20"
                        className="hidden"
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
            <tr>
              <td
                colSpan={
                  sessionStorage.getItem("technologyName") ===
                    "Metal (Estimation)" ||
                    sessionStorage.getItem("technologyName") ===
                    "Metal (Simulation)"
                    ? 5
                    : 6
                }
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  You can change the range for your variable from the bar to
                  specify analysis how one variable could impact the Cost Per
                  Part. Default value is calculated value of variable as per
                  default scenario numbers.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };
  const saveFormFieldsInDb = (formData) => {
    toggleMutation({
      variables: { calculations: formData },
      optimisticResponse: true,
    })
      .then((res) => { })
      .catch((error) => {
        console.log("Getting error from server");
      });
  };



  const AddScenarioMenu = () => (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <div className="main-box" onClick={handleClose}>
        <AiFillCloseCircle onClick={handleClose} className="close-icon" />
        <img src="/assets/SS/add_senerio.png" className="w-75" alt="" />
        <img src="/assets/SS/create_senerio.png" className="w-75" alt="" />
        <img src="/assets/SS/impact.png" className="w-75" alt="" />
        <img src="/assets/SS/sensitivity.png" className="w-75" alt="" />
        <img src="/assets/SS/cost_matrix.png" className="w-75" alt="" />
      </div>
    </Menu>
  );

  useEffect(() => {
    for (const scenario of scenarios) {
      formFields[scenario]["cost_per_part"] = {
        value:
          +formFields[scenario]?.["cpp_overhead_consumables"]?.value +
          +formFields[scenario]?.["total_cost_per_part"]?.value,
        override: false,
      };

      if (!useRenderButton && metalCo2ePerPart) {

        formFields[scenario]["cost_of_co2e_per_part"] = {
          value: metalCo2ePerPart,
          override: false,
        };
        formFields[scenario]["cost_of_producing_one_kg_co2e"] = {
          value: window.localStorage.getItem("costOneKGCo2"),
          override: false,
        };

        emitSocketBtn();
        getSensitivitySocketValues();
        renderMatrixSocket();
      } else if (
        useRenderButton &&
        Number(localStorage.getItem("metalCo2pp")) === 0
      ) {
        emitSocketBtn();
        getSensitivitySocketValues();
        renderMatrixSocket();
      } else {
        formFields[scenario]["overhead_and_consumables"] = {
          value: formFields[scenario]?.["cpp_overhead_consumables"]?.value,
          override: false,
        };
      }
    }
    // console.log("Form Fields: ", formFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metalCo2ePerPart, useRenderButton,]);

  useEffect(() => {
    const Data = sessionStorage.getItem("calculator_values_default")
    const formData = JSON.parse(Data)

    if (technology_name === "Polymer") {
      setpsData((prevState) => {
        return {
          ...prevState,
          type: "Polymer",
          setupTime: +formData?.setup_time?.value,
          buildTime: +formData?.build_time?.value,
          unpackingtime: +formData?.unpacking_time_per_job?.value,
          coolDownTime: +formData?.cool_down_time_in_system?.value,
          isOnlyWorkingHours: false,
        };
      });
    } else {
      setpsData((prevState) => {
        return {
          ...prevState,
          type: "Metal",
          setupTime: +formData?.setup_time?.value,
          buildTime: +formData?.build_time?.value,
          unpackingtime: +formData?.unpacking_time_per_job?.value,
          isOnlyWorkingHours: false,
        };
      });
    }
  }, [technology_name, psCheck])


  return (
    <React.Fragment>
      <ToastContainer />
      <Modal show={analyticsShow} onHide={handleCloseAnalyticsNameModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enter analytics name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setAnalyticsName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter analytics name"
              value={analyticsName}
            />
            {/* <p className="edit-icon value-unit">kg</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleCloseAnalyticsNameModal}
            >
              Close
            </button>
            <Button
              disabled={analyticsName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                SaveAnalytics();
                handleCloseAnalyticsNameModal();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={scenarioModal} onHide={handleClosescenarioModal} className="scenario-modal-spacing" backdrop="false" >
        <Modal.Body className="p-0">
          <div id="popup-box">
            <div id="popup-details">
              <p>Enter new scenario name</p>
              <div className="inputbox ">
                <input type="text" className="login-input" id="scenario-input" />
              </div>
              <div id="btn-row2">
                <div className="button3 gray" id="cancel-btn" onClick={() => handleClosescenarioModal()} >
                  <p>Cancel</p>
                </div>
                <div
                  className="button3"
                  id="add-scenario-cancel-btn"
                  onClick={() => {
                    addScenario();
                    handleClosescenarioModal()
                  }}
                >
                  <p>Confirm</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {useRenderButton && <Navbar />}
      {!useRenderButton &&
        history.location.pathname === "/calculator/visual-analytics/false" &&
        Number(sessionStorage.getItem("Carbon")) === 1 && <Navbar />}

      <ReactTooltip />
      <div className="row pad-50 ">
        <div className="col p-0 mb-5">
          <div id="title-row">
            <div id="center-title2">
              <p className="t-2">Visual Analytics</p>
              <p className="s-1">{technologyName}</p>
            </div>

            {Access && savedlist === "true" ? (
              <div className="d-flex flex-column align-content-center" >
                <div className="d-flex justify-content-center align-items-center" id='va-btn-container'>
                  <div className="btn-container">
                    {savedlist === "false" ? (
                      <></>
                    ) : useRenderButton ? (
                      <>
                        {
                          sessionStorage.getItem("technologyName") !== "Metal (Estimation)" &&
                          sessionStorage.getItem("technologyName") !== "Polymer (Estimation)" &&
                          (
                            !isEnbaleCarbonButton ?
                              <>
                                <TooltipTrippy
                                  fieldName={
                                    <Button
                                      disabled={!isEnbaleCarbonButton}
                                      className="selection-button "
                                      id='carbon-button'
                                      onClick={handleCarbonCalculatorRoute}
                                    >Carbon Calculator
                                    </Button>
                                  }
                                  tooltipMessage="Carbon Calculator's data is currently unavilable for this combination of machine and material. Kindly contact EOS' Additive Minds team for more information on the same.
                                Note: Please click on CONTACT US button for reaching out to us." />
                              </> :
                              <Button
                                disabled={!isEnbaleCarbonButton}
                                className="selection-button "
                                id='carbon-button'
                                onClick={handleCarbonCalculatorRoute}
                              >Carbon Calculator
                              </Button>
                          )
                        }
                      </>
                    ) : (
                      <button
                        className="selection-button "
                        onClick={ScenarioState}
                        id='carbon-button'
                      >
                        Report
                      </button>
                    )}
                  </div>
                  <button
                    className="button2 rounded ml-auto"
                    onClick={() => handleShowAnalyticsNameModal()}
                  >
                    Save Analytics
                  </button>
                </div>

                <div
                  id="download-form-button"
                  className="button2 rounded ml-auto mt-3"
                  onClick={() => {
                    setIsOpen(true);
                    history.push("/calculator/generated-report")
                    setCostData({ ...formFields[selectedScenarioName] });

                  }}
                //  onClick={(e) => {
                //   sessionStorage.setItem('scenario_name', 'default');
                //   sessionStorage.setItem('calculator_values_default', JSON.stringify(formFields.Default? formFields.Default.Default? formFields.Default.Default : formFields.Default : formFields ))
                //   saveFormFieldsInDb(formFields)
                // }}
                >
                  <img alt="" src="/assets/report.png" width="23" height="24" />
                  <p>Generate Report </p>
                </div>
              </div>
            ) : Access && savedlist === "false" ? (
              <div className="d-flex flex-column align-content-center" >
                <div className="btn-container">
                  <button
                    className="selection-button ml-0"
                    onClick={() => handleShowAnalyticsNameModal()}
                  >
                    Save Analytics
                  </button>
                </div>
                <div
                  id="download-form-button"
                  className="button2 rounded ml-auto mt-3"
                  onClick={() => {
                    setIsOpen(true);
                    history.push("/calculator/generated-report")
                    setCostData({ ...formFields[selectedScenarioName] });

                  }}
                >
                  <img alt="" src="/assets/report.png" width="23" height="24" />
                  <p>Generate Report </p>
                </div>
              </div>
            ) : Number(sessionStorage.getItem("Carbon")) === 1 ? (
              <>
                <div className="btn-container">
                  <button
                    className="selection-button mr-5"
                    onClick={ScenarioState}
                  >
                    Report
                  </button>
                  <button
                    className="selection-button ml-0"
                    onClick={() => handleShowAnalyticsNameModal()}
                  >
                    Save Analytics
                  </button>
                </div>
              </>
            ) : (
              <div
                id=""
                className="button2   myorder2-2"
                style={{ cursor: "not-allowed" }}
              >
                <div className="d-flex">
                  <img src="/assets/report.png" width="23" height="24" alt="" />
                  <p>Generate Report</p>
                  {!Access ? (
                    <a
                      href="/premium-request"
                      className="premium-row-text ml-1"
                    >
                      Premium
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          {Access ? (
            <>
              <div
                id="preview-sacnerio-button"
                onClick={() => previewScenarioPdf()}
                className="preview-scenario-button"
              >
                {renderDownloadScenarioBtn()}
              </div>
              <div id="sceanrio-row">
                {renderScenarios()}
                {renderCreateScenarioBtn()}
              </div>
              <div id="fine-tune-title">
                <p>Fine Tune Scenario</p>
                <img
                  src="/assets/down-arrow.png"
                  alt=""
                  height="15"
                  width="26"
                  id="toggle-btn"
                />
              </div>
              <div id="fine-tune-content">
                <div>
                  {techSteps.map((steps, index) => {
                    return (
                      <a
                        id={steps.step_id}
                        key={steps.name}
                        href={"#" + steps.name.replace(/ /g, "_")}
                        onClick={() => {
                          handleHighlight(steps.step_id);
                        }}
                        className={
                          "menu-item " + (index === 0 ? "highlight" : "")
                        }
                      >
                        <p>{steps.name}</p>
                      </a>
                    );
                  })}
                </div>
                <div className="overflow-auto">
                  {formHolder === "form-holder-1" ? (
                    <div id="form-holder">{renderFields()}</div>
                  ) : (
                    <div id="form-holder">
                      {scenarios.map(() => {
                        return renderFields();
                      })}
                    </div>
                  )}
                  <div id="fade"></div>
                </div>
                {/* <div className="ml-5">
                  <div className="powder-table-top">
                    <p className="powder-heading">Carbon Pricing</p>
                  </div>
                  {
                    history.location.pathname === "/carboncalculator/carbon-polymer" &&
                    <>
                      {
                        Object.keys(socketObj).length !== 0 && <Co2polymerProcess Socket={socketObj} StepID={84} />
                      }
                    </>
                  }
                  {
                    history.location.pathname === "/carboncalculator/carbon-metal" && <>
                      {
                        Object.keys(socketObj).length !== 0 && <Co2Pricing Socket={socketObj} staticDataOfMachine={CostData1} />
                      }
                    </>
                  }

                </div> */}
              </div>
              <button
                className="button3 myorder2-1"
                id="submitsensitivitydatasocket"
                style={{
                  marginLeft: "41%",
                  width: "20%",
                }}
                onClick={() => {
                  emitSocketBtn();
                }}
              >
                Submit
              </button>
            </>
          ) : (
            <div className="add-scenario">
              <div className="row">
                <div className="h-100 col-6 col-md-9" onClick={handleOpen}>
                  <p>
                    Have the possibility to <strong>add/edit scenarios</strong>,
                    perform <strong>sensitivity analysis</strong>, analyze{" "}
                    <strong>cost matrix</strong> using our Premium access –{" "}
                    <AiOutlineEye className="view-scenarios ml-2" />{" "}
                  </p>
                </div>
                <div className="col-6 col-md-3 text-right">
                  <strong className="sign-up-text blue-text">
                    <a
                      href="/premium-request"
                      className="premium-row-text py-1"
                      style={{ fontSize: "15px" }}
                    >
                      Request your Premium access
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          )}
          <div id="visuals">
            {dognutDownloadPdf === true && dognutChartsUrls.length > 0 && (
              <DognutPdfGenerator data={formFields} scenarios={scenarios} images={dognutChartsUrls} imagesTwo={dognutChartsUrlsTwo} isCarbonPricingAvailable={!useRenderButton && metalCo2ePerPart !== undefined} pathNameCheck={(history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")} />
            )}
            <div id="dognut-id">
              <p className="figure-title">
                COST DISTRIBUTION %{" "}
                <button
                  onClick={() => {
                    let dognutUrls = dognutChartsUrls;
                    dognutUrls = [];
                    let dognutUrlsTwo = [];
                    for (let index = 0; index < scenarios.length; index++) {
                      const url = document
                        .getElementById(`d1-${scenarios[index]}-${index}`)
                        .toDataURL("image/png");
                      dognutUrls.push(url);
                      let urlTwo
                      if (!((history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)"))) {
                        urlTwo = document
                          .getElementById(`d2-${scenarios[index]}-${index}`)
                          .toDataURL("image/png");
                        dognutUrlsTwo.push(urlTwo);
                      }
                    }
                    setDognutChartsUrls(dognutUrls);
                    setDognutChartsUrlsTwo(dognutUrlsTwo);
                    setDognutDownloadPdf(true);
                    setTimeout(() => {
                      setDognutDownloadPdf(false);
                    }, 2000);
                  }}
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>
              </p>
              <div className="chartholder">
                {/* <PieChart data={formFields}/> */}
                {scenarios.map((scenario, index) => {
                  let isEditableTextDisable = selectedScenarioName === scenario;
                  return (
                    <>
                      <div className={formFields[scenarios[index]] && formFields[scenarios[index]]["hetrojobs"] && formFields[scenarios[index]]["hetrojobs"].length > 1 ? "hidden" : "donutbox"}>
                        <div id="donut">
                          <canvas
                            id={`d1-${scenario}-${index}`}
                            className="donutImageOne"
                          ></canvas>
                        </div>
                        {
                          formFields && !!Object.keys(formFields)?.length && formFields[scenario] && !!Object.keys(formFields[scenario])?.length ?
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  marginTop: "10px",
                                  marginRight: "5px",
                                }}
                              >
                                {scenario !== constant?.DEFAULT_SCENARIO ?
                                  (
                                    <>
                                      <EditText
                                        key={index}
                                        name="scenario"
                                        style={{
                                          fontSize: "16px",
                                          border: "1px solid #ccc",
                                        }}
                                        value={scenario}
                                        readonly={isEditableTextDisable}
                                        onChange={(event) => {
                                          let temp = [...scenarios];
                                          temp[index] = event.target.value;
                                          formFields[event.target.value] =
                                            formFields[scenario];
                                          delete formFields[scenario];
                                          setFormFields(formFields);
                                          setScenarios((oldArray) => [...temp]);
                                        }}
                                      />
                                      Cost per Part
                                    </>
                                  ) : (
                                    <>
                                      {scenarioCount < 2 ? scenario :
                                        <EditText
                                          key={index}
                                          name="scenario"
                                          style={{
                                            fontSize: "16px",
                                            border: "1px solid #ccc",
                                          }}
                                          value={scenario}
                                          readonly={isEditableTextDisable}
                                        />

                                      } Cost per Part
                                    </>
                                  )}
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                System:{" "}
                                <b>
                                  {
                                    formFields[scenario]["system_cost_per_part"]
                                      ?
                                      <>
                                        {
                                          +formFields[scenario]["system_cost_per_part"]
                                            .value}{" "}
                                        {regionObj?.currency_symbol}
                                      </>

                                      : 0}
                                </b>
                              </p>
                              {
                                (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                  ?
                                  <></>
                                  :
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Recurring:{" "}
                                    <b>
                                      {
                                        formFields[scenario]["recurring_cost_per_part"]
                                          ?
                                          <>
                                            {
                                              +formFields[scenario]["recurring_cost_per_part"]
                                                .value}{" "}
                                            {regionObj?.currency_symbol}
                                          </>

                                          : 0}
                                    </b>
                                  </p>
                              }
                              <p
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                Material:{" "}
                                <b>
                                  {
                                    formFields[scenario]["material_cost_per_part"]
                                      ?
                                      <>
                                        {+formFields[scenario]["material_cost_per_part"]
                                          .value}{" "}
                                        {regionObj?.currency_symbol}
                                      </>
                                      : 0}
                                </b>
                              </p>
                              {
                                (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                  ?
                                  <></>
                                  :
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Consumables:{" "}
                                    <b>
                                      {
                                        formFields[scenario]["cpp_consumables"]
                                          ?
                                          <>
                                            {+formFields[scenario]["cpp_consumables"]
                                              .value}{" "}
                                            {regionObj?.currency_symbol}
                                          </>
                                          : 0}
                                    </b>
                                  </p>
                              }
                              <p
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                    ?
                                    <>
                                      Total Cost Per Part:{" "}
                                    </>
                                    :
                                    <>
                                      Total AM CPP:{" "}
                                    </>
                                }
                                {Access ? (
                                  !useRenderButton && metalCo2ePerPart !== undefined ? (
                                    <b>
                                      {
                                        formFields[scenario]["total_am_cpp"]
                                          ? <> {(
                                            +formFields[scenario]["total_am_cpp"]?.value).toFixed(2)} {regionObj?.currency_symbol}
                                          </>
                                          : 0}
                                    </b>
                                  ) : (
                                    <b>
                                      {
                                        formFields[scenario]["cost_per_part"]
                                          ?
                                          headconsumables
                                            ?
                                            <>
                                              {(
                                                +formFields[scenario]["total_am_cpp"]
                                                  ?.value
                                              ).toFixed(2)} {regionObj?.currency_symbol}
                                            </>
                                            :
                                            <>
                                              {(
                                                +formFields[scenario]["total_cost_per_part"]
                                                  ?.value
                                              )} {regionObj?.currency_symbol}
                                            </>
                                          : 0}
                                    </b>
                                  )
                                ) : (
                                  <a
                                    href="/premium-request"
                                    className="premium-row-text "
                                  >
                                    Premium
                                  </a>
                                )}
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                Post Processing:{" "}
                                {Access ? (
                                  <b>
                                    {
                                      formFields[scenario]["post_processing_cost_per_part"]
                                        ?
                                        <>
                                          {+formFields[scenario]["post_processing_cost_per_part"].value}{regionObj?.currency_symbol}
                                        </>
                                        : 0}
                                  </b>
                                ) : (
                                  <a
                                    href="/premium-request"
                                    className="premium-row-text "
                                  >
                                    Premium
                                  </a>
                                )}
                              </p>
                              {

                                (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                  ?
                                  <></>
                                  :
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                      paddingBottom: !useRenderButton && metalCo2ePerPart !== undefined ? 0 : "20px",
                                    }}
                                  >
                                    Overhead:{" "}
                                    <b>

                                      {formFields[scenario]["cpp_overhead"] ? formFields[scenario]["cpp_overhead"]?.value : ""} {" "}  {regionObj?.currency_symbol}

                                    </b>
                                  </p>

                              }
                              {!useRenderButton && metalCo2ePerPart !== undefined ? (
                                <p
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    paddingBottom: !useRenderButton && metalCo2ePerPart !== undefined ? "20px" : 0,
                                  }}
                                >
                                  Carbon Pricing:{" "}
                                  <b>
                                    {
                                      formFields[scenario]["cost_of_co2e_per_part"]
                                        ?
                                        <>
                                          {
                                            (parseFloat(formFields[scenario]["cost_of_co2e_per_part"]?.value)).toFixed(2)
                                          }
                                          {regionObj?.currency_symbol}
                                        </>
                                        : 0}
                                  </b>
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                            :
                            <></>
                        }


                      </div>
                      {
                        !((history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")) &&
                        (
                          <div className="donutbox">
                            <div id="donut">
                              <canvas
                                id={`d2-${scenario}-${index}`}
                                className="donutImage"
                              ></canvas>
                            </div>
                            {
                              formFields && !!Object.keys(formFields)?.length && formFields[scenario] && !!Object.keys(formFields[scenario])?.length ?
                                <>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {scenario !== constant?.DEFAULT_SCENARIO ?
                                      (
                                        <>
                                          <EditText
                                            key={index}
                                            name="scenario"
                                            style={{
                                              fontSize: "16px",
                                              border: "1px solid #ccc",
                                            }}
                                            value={scenario}
                                            readonly={isEditableTextDisable}
                                            onChange={(event) => {
                                              let temp = [...scenarios];
                                              temp[index] = event.target.value;
                                              formFields[event.target.value] =
                                                formFields[scenario];
                                              delete formFields[scenario];
                                              setFormFields(formFields);
                                              setScenarios((oldArray) => [...temp]);
                                            }}

                                          />
                                          Cost per cm3
                                        </>
                                      ) : (
                                        <>
                                          {scenarioCount < 2 ? scenario :
                                            <EditText
                                              key={index}
                                              name="scenario"
                                              style={{
                                                fontSize: "16px",
                                                border: "1px solid #ccc",
                                              }}
                                              value={scenario}
                                              readonly={isEditableTextDisable}
                                            />

                                          } Cost per cm3
                                        </>
                                      )}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    System:{" "}
                                    <b>
                                      {
                                        formFields[scenario]["system_cost_per_cm3"]
                                          ?
                                          <>
                                            {
                                              formFields[scenario]["system_cost_per_cm3"]?.value}{" "}{regionObj?.currency_symbol}
                                          </>

                                          : 0
                                      }
                                    </b>
                                  </p>
                                  {
                                    (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                      ?
                                      <></>
                                      :
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Recurring:{" "}
                                        <b>
                                          {
                                            formFields[scenario]["recurring_cost_per_cm3"]
                                              ?
                                              <>
                                                {+formFields[scenario]["recurring_cost_per_cm3"]?.value}{" "} {regionObj?.currency_symbol}
                                              </>
                                              : 0
                                          }
                                        </b>
                                      </p>
                                  }
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Material:{" "}
                                    <b>
                                      {
                                        formFields[scenario]["material_cost_per_cm3"]
                                          ?
                                          <>
                                            {+formFields[scenario]["material_cost_per_cm3"]?.value}{" "}
                                            {regionObj?.currency_symbol}
                                          </>
                                          :
                                          0
                                      }
                                    </b>
                                  </p>
                                  {
                                    (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                                      ?
                                      <></>
                                      :
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Consumables:{" "}
                                        <b>
                                          {
                                            formFields[scenario]["consumable_cost_per_cm3"]
                                              ?
                                              <>
                                                {+formFields[scenario]["consumable_cost_per_cm3"]?.value}{" "}
                                                {regionObj?.currency_symbol}
                                              </>
                                              :
                                              0
                                          }
                                        </b>
                                      </p>
                                  }
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Total AM Cost per cm3: {" "}
                                    {Access ? (
                                      !useRenderButton && metalCo2ePerPart !== undefined ? (
                                        <b>
                                          {
                                            formFields[scenario]["total_am_cost_cm3"]
                                              ?
                                              <>
                                                {
                                                  parseFloat(formFields[scenario]["total_am_cost_cm3"]?.value).toFixed(2)
                                                }
                                                {regionObj?.currency_symbol}
                                              </>
                                              : 0
                                          }
                                        </b>
                                      ) : (
                                        <b>
                                          {
                                            formFields[scenario]["cost_per_part"]
                                              ?
                                              headconsumables
                                                ?
                                                <>
                                                  {
                                                    formFields[scenario]["total_am_cost_cm3"] ?
                                                      <>
                                                        {parseFloat(formFields[scenario]["total_am_cost_cm3"]?.value).toFixed(2)} {regionObj?.currency_symbol}
                                                      </>
                                                      :
                                                      0
                                                  }

                                                </>
                                                :
                                                <>
                                                  {
                                                    formFields[scenario]["total_cost_per_part"] ?
                                                      <>
                                                        {formFields[scenario]["total_cost_per_part"]?.value} {regionObj?.currency_symbol}
                                                      </>
                                                      :
                                                      0
                                                  }
                                                </>
                                              :
                                              0
                                          }
                                        </b>
                                      )
                                    ) : (
                                      <a
                                        href="/premium-request"
                                        className="premium-row-text "
                                      >
                                        Premium
                                      </a>
                                    )}
                                  </p>

                                  {!useRenderButton && metalCo2ePerPart !== undefined ? (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        paddingBottom: !useRenderButton && metalCo2ePerPart !== undefined ? "20px" : 0,
                                      }}
                                    >
                                      Carbon Pricing:{" "}
                                      <b>
                                        {
                                          formFields[scenario]["cost_of_co2e_per_part"]
                                            ? <>
                                              {
                                                parseFloat(formFields[scenario]["cost_of_co2e_per_part"]?.value).toFixed(2)
                                              }
                                              {regionObj?.currency_symbol}
                                            </>
                                            : 0}
                                      </b>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </>
                                :
                                <></>
                            }
                          </div>
                        )
                      }
                    </>
                  );
                })}
              </div>
              <div className="center mt-5">
                <div className="legend">
                  <div className="custom-legend-item">
                    <div className="custom-legend-icon steeltblue"></div>
                    <p>System</p>
                  </div>
                  {
                    (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                      ?
                      <></>
                      :
                      <div className="custom-legend-item">
                        <div className="custom-legend-icon petrollight"></div>
                        <p>Recurring</p>
                      </div>
                  }
                  <div className="custom-legend-item">
                    <div className={`custom-legend-icon ${(history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)") ? "petrollight" : 'steelbluebright'}`}></div>
                    <p>
                      Material
                    </p>
                  </div>
                  {
                    (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)") && (
                      <div className="custom-legend-item">
                        <div className="custom-legend-icon steel"></div>
                        <p>
                          Post Processing
                        </p>
                      </div>
                    )
                  }

                  {
                    (history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                      ?
                      <></>
                      :
                      <div className="custom-legend-item">
                        <div
                          style={{ backgroundColor: "#0095c8" }}
                          className="custom-legend-icon "
                        ></div>
                        <p>Consumables</p>
                      </div>
                  }
                  {!useRenderButton && metalCo2ePerPart !== undefined ? (
                    <div className="custom-legend-item">
                      <div
                        style={{ backgroundColor: "#00bff3" }}
                        className="custom-legend-icon "
                      ></div>
                      <p>Carbon Pricing</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div id="tcpp-g">
              <BCPPGraph formFields={formFields} scenarios={scenarios} />
            </div>
            <div className="py-2 d-flex justify-content-between align-items-center flex-column">
              <div className="powder-container">
                <div className="powder-heading-top head-padding">
                  <p className="powder-heading">
                    <TooltipTrippy
                      tooltipMessage="The following production scenarios and the data calculated below are based on the Cost Per Part analysis. Changes in these production scenarios are not reflected in the Cost Per Part analysis."
                      fieldName="Production scenarios"
                    />
                  </p>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Builds per week</p>

                  <p className="output-heading">
                    {totalJobs == null ? "Not Available" : totalJobs}
                  </p>
                </div>
                <i className="px-4">
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Working weeks per year</p>

                  <p className="output-heading">
                    <EditText
                      name="working-weeks-per-year"
                      style={{ fontSize: "16px", border: "1px solid #ccc" }}
                      value={workingWeeksPerYear}
                      onChange={(event) =>
                        setWorkingWeeksPerYear(event.target.value)
                      }
                    />
                  </p>
                </div>
                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Jobs per year</p>
                  <p className="output-heading">
                    {jobYear == null ? "Not Available" : Math.round(jobYear)}
                  </p>{" "}
                </div>
                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Throughput per year</p>
                  <p className="output-heading">
                    {Math.trunc(+(jobYear * +formFields?.Default?.no_of_parts?.value))}
                  </p>{" "}
                </div>

                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Turnaround time</p>
                  <p className="output-heading">{`${singleJobTime} h`}</p>{" "}
                </div>

                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Operators needed per machine</p>
                  <p className="output-heading">{+formFields?.Default?.number_of_operators?.value ? +formFields?.Default?.number_of_operators?.value : 1}</p>{" "}
                </div>
                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">Number of shifts</p>
                  <p className="output-heading">{workingHours === "true" ? 1 : 3}</p>{" "}
                </div>
                <i className="px-4">
                  {" "}
                  <hr />
                </i>
                <div className="d-flex justify-content-between px-4">
                  <p className="input-heading">
                    Operator’s time needed per job
                  </p>
                  <p className="output-heading">{+formFields?.Default?.unpacking_time_per_job?.value + +formFields?.Default?.setup_time?.value} h</p>{" "}
                </div>
              </div>
              <h2 className="mt-5">Weekly production scheduling scenario</h2>

              <ProductionScenario
                getjobsWeek={getjobsWeek}
                staticData={psData}
                setStaticData={setpsData}
                getTurnAroundTime={getTurnAroundTime}
                type={technology_name}
                setPsImage={setPsImage}
              />
            </div>
            {
              // socketObj !== undefined && <CO2eSensitivityGraph Socket={socketObj} />
            }


            {Access ? (
              <>
                {/* <p
                  data-tip="This refers to the impact on Cost per part (CPP), if you change the variable by 10% and 20%. Note this is to have a positive change on cost per part for e.g. 10% increase in utilization and 10% decrease in material price. "
                  className="figure-title chart-heading" onClick={() => {
                    executeRenderBigGraphSocket(true);
                  }}>TOP 5 CPP IMPACT VARIABLES <i class="fa fa-caret-down" aria-hidden="true"></i>
                </p> */}
                {/* {useRenderButton && renderCppLoader()} */}

                {sensitivityDownloadPdf === true &&
                  sensitivityChartUrls.length > 0 && (
                    <SensitivityPdfGenerator images={sensitivityChartUrls} />
                  )}
                <p
                  data-tip="You can change the range for your variable from the bar to specify analysis how one variable could impact the Cost Per Part "
                  className="figure-title chart-heading mt-4"
                  onClick={getSensitivitySocketValues}
                >
                  SENSITIVITY{" "}
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </p>
                <div className="dropdown-content">
                  {renderSensitivityLoader()}
                  <p
                    className="figure-title"
                    style={{ position: "absolute", right: "20%" }}
                  >
                    <button
                      onClick={() => {
                        let sensitivityUrls = sensitivityChartUrls;
                        sensitivityUrls = [];
                        for (
                          let index = 0;
                          index < sensitivityLegends.length;
                          index++
                        ) {
                          const url = document
                            .getElementById(
                              `${sensitivityLegends[index].key}-ml1`
                            )
                            .toDataURL("image/png");
                          sensitivityUrls.push(url);
                        }
                        setSensitivityChartUrls(sensitivityUrls);
                        setSensitivityDownloadPdf(true);
                        setTimeout(() => {
                          setSensitivityDownloadPdf(false);
                        }, 2000);
                      }}
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>{" "}
                  </p>
                  <div className="chartholder2">
                    <div id="minichartholder">
                      <div className="container-fluid  ">
                        <div className="row ">
                          {sensitivityLegends &&
                            sensitivityLegends.map((legend) => {

                              return (
                                <>
                                  <div className="col-sm-12 col-md-4">
                                    <div className="minichart">
                                      <canvas
                                        id={`${legend?.key}-ml1`}
                                      ></canvas>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          position: "absolute",
                                          left: "35%",
                                          top: "275px",
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          color: "#666666",
                                        }}
                                      >
                                        Default Value:{" "}
                                        {formFields[constant?.DEFAULT_SCENARIO] && formFields[constant?.DEFAULT_SCENARIO][
                                          legend?.key
                                        ] &&
                                          formFields[
                                            constant?.DEFAULT_SCENARIO
                                          ][legend?.key].value}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                        <div>
                          {/* <CO2eSensitivityGraph socket={socket} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className="figure-title chart-heading mt-4"
                  onClick={renderMatrixSocket}
                >
                  COST MATRIX ({currency}/PART){" "}
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </p>

                <div className="chartholder2 dropdown-content pt-4">
                  {heatMapDownloadPdf === true && heatMap && (
                    <HeatMapPdfGenerator
                      image={heatMap}
                      legendsInfo={{
                        xaxis: matrixX["key"],
                        yaxis: matrixY["key"],
                        targetPrice: targetValue,
                      }}
                    />
                  )}

                  {renderMatrixLoader()}
                </div>
              </>
            ) : (
              <>
                <p
                  data-tip="This refers to the impact on Cost per part (CPP), if you change the variable by 10% and 20%. Note this is to have a positive change on cost per part for e.g. 10% increase in utilization and 10% decrease in material price. "
                  className="figure-title"
                >
                  TOP 5 CPP IMPACT VARIABLES
                  <a href="/premium-request" className="premium-row-text ">
                    Premium
                  </a>
                </p>
                <p
                  data-tip="You can change the range for your variable from the bar to specify analysis how one variable could impact the Cost Per Part."
                  className="figure-title"
                >
                  SENSITIVITY
                  <a href="/premium-request" className="premium-row-text ">
                    Premium
                  </a>
                </p>
                <p className="figure-title">
                  COST MATRIX ({currency}/PART)
                  <a href="/premium-request" className="premium-row-text ">
                    Premium
                  </a>
                </p>
              </>
            )}
          </div>
        </div>
        <AddScenarioMenu />
      </div>
      {isOpen && (
        <GenerateReportPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          scenarios={scenarios}
          setSelectedScenarioName={setSelectedScenarioName}
          formFields={formFields}
        />
      )}
    </React.Fragment>
  );
};

export default Analytics;
