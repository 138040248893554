import React from "react";
import { BlobProvider } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Co2InPdf from "app/components/Co2InCostReports/Co2InPdf";
import Html from 'react-pdf-html';
import config from '../../../../../../../config';
import { toast } from 'react-toastify';
import { NOTIFICATION_MESSAGES } from "app/components/ConditionalEnums";
import { saveAs } from "file-saver";
import { Technology_Name } from "utils/constantenums";
const axios = require('axios').default;
const styles = StyleSheet.create({
    page: { backgroundColor: 'white', color: 'black', fontSize: 14, display: "flex", flexDirection: "column", width: "100%" },
    headerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: "10px 7% 10px 3%", alignItems: "center", width: "100%" },
    logo: { height: 50 },
    logoTwo: { height: 80 },
    header: { width: '35%', fontWeight: "ultrabold", fontSize: 20, textAlign: "center" },
    reportsSection: { width: '100%', display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", marginTop: 20 },
    reportsDetailsSection: { width: '100%', display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "#fffff", paddingTop: 22.5, paddingBottom: 22.5, paddingLeft: 45, paddingRight: 45 },
    reportsDetailInner: {
        paddingTop: 22.5,
        display: "flex",
        flexDirection: "row",
    },
    ultraBold: {
        fontWeight: 'ultrabold',
        fontSize: 18
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    detailsView: { padding: 15 },
    dataField: {
        backgroundColor: "#f2f2f2",
        width: "100%",
        minWidth: 36,
        minHeight: 36,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: " center",
        borderRadius: 7.5,
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 9,
        padding: 12,
        marginBottom: 12,
        color: "#99a4b4",
        border: "none !important",
        boxShadow: "none !important",
    },
    inputHeading: {
        fontStyle: "normal",
        fontWeight: "ultrabold",
        fontSize: 10.5,
        color: "black",
        marginBottom: 6,
    },
    inputHeading2: {
        fontStyle: "normal",
        fontWeight: "ultrabold",
        fontSize: 10.5,
        color: "#fff",
        marginBottom: 6,
    },
    outputHeading: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 10.5,
        color: "#99a4b4",
        marginBottom: 6
    },
    powderContainer: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        // paddingBottom: 30,
        backgroundColor: "#ffffff",
        marginTop: 10,
        borderRadius: 10,
    },
    powderTableTop: {
        width: "100%",
        backgroundColor: "#156481",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        display: "flex",

        padding: '10 20',
        maxHeight: 40,
    },
    powderTableTopTwo: {
        width: "100%",
        backgroundColor: "#156481",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        display: "flex",

        maxHeight: 40,
    },
    powderHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 16,
        color: "#99a4b4",
        paddingTop: 20,
        paddingBottom: 20,
    },
    powderHeadingTwo: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,
        color: "#99a4b4",
        paddingTop: 20,
        paddingBottom: 20,
    },
    powderTableHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,

        color: "#fff",
    },
    powderTableHeading2: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,

        color: "#000",
    },
    totalBottom: {
        backgroundColor: "#156481",
        borderRadius: 10,
        marginBottom: 0,
        display: "flex",
        justifyContent: "space-between",
    },
    partsSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 140,
        marginBottom: 60,
    },
    dayBorder1: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12
    },
    dayBorder2: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12
    },
    partsHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 28,
        color: "#252525",
    },
    partsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    dayContainerSpacing: {
        marginRight: 30,

    },
    dayContainer: {
        width: 200,
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10.5 6',
    },
    tomorrowBg1: {
        backgroundColor: "#4EA0B0",
    },
    tomorrowBg2: {
        backgroundColor: "#56AFC0",
    },
    tomorrowBg3: {
        backgroundColor: "#5DC0D3",
    },
    tomorrowBg4: {
        backgroundColor: "#5FCCE1",
    },
    todayBg1: {
        backgroundColor: "#156481",
    },
    todayBg2: {
        backgroundColor: "#1B7395"
    },
    todayBg3: {
        backgroundColor: "#1F7FA4"
    },
    todayBg4: {
        backgroundColor: "#1E88B0"
    },
    dayText: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,
        color: "#99a4b4",
    },
    color2: {
        color: "#99a4b4"
    },
    color1: {
        color: "black"
    },
    graphSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 140,
    },
    graphHeading: {
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 36,
        lineHeight: 49,

        color: "#252525",
        paddingBottom: 60,
    },
    divTable: {
        display: "table",
        width: "100%",
    },
    divTableBody: {
        display: "flex",
        flexDirection: "column"
    },
    divTableRow: {
        display: "table-row"
    },
    divTableCell1Heading: {
        display: "table-cell",
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 12,

        color: "#252525",
        padding: "0 10 16",
        minWidth: 85,
    },
    divTableCell1: {
        display: "table-cell",
        fontStyle: "normal",
        fontWeight: "semibold",
        fontSize: 10,

        color: "#99a4b4",
        padding: "0 10 1",
        minWidth: 85,
    },
    responsiveScroll: {
        width: "50%"
    },
    colorIcon: {
        width: 16,
        height: 16,
        borderRadius: 3,
        marginRight: 8,
    },
    bgColor5: {
        background: '#07BEB8'
    },
    bgColor6: {
        background: '#68D8D6'
    },
    bgColor7: {
        background: ' #9CEAEF'
    },
    colorRepValue: {
        fontStyle: 'normal',
        fontWeight: "semibold",
        fontSize: 14,
        lineHeight: 19,
        textTransform: 'capitalize',
        color: '#99a4b4',
        marginRight: 40,
    },
    singleDaySchedule: {
        display: "flex",
        flexDirection: "row",
        width: '100%',
        // min-width: 154.88px;
        // height: 60,
        position: 'relative'
    },
    singleDayGraph: {
        height: '100%',

    },
    partInfoView: { display: 'flex', flexDirection: 'row' },
    colorPalletePP: { width: 10, height: 10, backgroundColor: '#7761cf' },
    colorPalleteSCPP: { width: 10, height: 10, backgroundColor: '#fcc342' },
    colorPalleteTCPP: { width: 10, height: 10, backgroundColor: '#00bff3' },
    p0: { padding: "0" },
    w_100: { width: '100%' },
    w_90: { width: '90%' },
    w_60: { width: '60%' },
    w_50: { width: '50%' },
    w_40: { width: '40%' },
    w_33: { width: '33.3%' },
    w_30: { width: '30%' },
    w_20: { width: '20%' },
    w_25: { width: '25%' },
    w_15: { width: '15%' },
    w_10: { width: '10%' },
    d_flex: { display: 'flex' },
    row: { flexDirection: 'row' },
    singlelinetext: { whiteSpace: "nowrap" },
    para_font_size: { fontSize: '10px' },
    divider: {
        width: 0,
        height: 50,
        border: 1,
        borderColor: "#CFCFCF"
    },
    tableView: { fontSize: 12 },
    fieldText: { display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 },
    footerBox: { display: 'flex', justifyContent: 'end', alignItems: 'center' },
    footerView: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 10, position: 'absolute', bottom: -100, left: -50, width: '100%' },
});
const styleSheet2 = {
    ul: {
        width: "80%"
    },
    li: {
        fontSize: 10
    }
}
const Doc = ({ formObj, pdfName, typeDocument, cppData, costOfCo2pp, currency, visualDonut, machineName, materialName, parameterName, jobsPerWeek, totalJobs, singleJobTime, machineImage, psImage, cppSum, workingHours, hotspotArray, highestIndex, hightlightKey, isHotSpotAvailable, technology_name }) => (
    <Document>
        <Page size="C3" style={styles.page}>
            <View style={[styles.w_100, styles.headerView]} fixed>
                <Image style={[styles.logo]} src="../assets/eosLogo.png" />
                <View style={[styles.w_40, { display: 'flex' }]}>
                    <Text style={{ fontWeight: "ultrabold", fontSize: 20, textAlign: "center", color: "#156481" }}>{formObj?.application?.value ? formObj?.application?.value : "Cost Calculator Report"}</Text>
                    <Text style={{ fontWeight: "ultrabold", fontSize: 20, textAlign: "center", color: "#156481" }}>{pdfName} </Text>
                </View>

                <Image style={[styles.logoTwo]} src="../assets/logo.png" />
            </View>
            <View style={[styles.reportsSection,]}>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: "0 5% ", alignItems: "center", marginBottom: 20, width: typeDocument === 0 ? "100%" : "95%" }}>

                    <View style={[styles.powderContainer, styles.w_50, { marginRight: "37px" }]}>
                        <View style={[styles.powderTableTop, { borderColor: "#156481", borderWidth: 1, flexDirection: "row", justifyContent: "space-between" }]}>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Part information</Text>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>{formObj?.part_name ? formObj?.part_name?.value : ""}</Text>

                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                            <Text style={[styles.inputHeading]}>
                                Material
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {materialName ? `${materialName} ` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                            <Text style={[styles.inputHeading]}>
                                Material price
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.material_price?.value ? `${formObj?.material_price?.value} ${currency}/kg` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                            <Text style={[styles.inputHeading]}>
                                Part name
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.part_name?.value ? formObj?.part_name?.value : ''}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                            <Text style={[styles.inputHeading]}>
                                Number of parts
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.no_of_parts?.value ? formObj?.no_of_parts?.value : 0}
                            </Text>
                        </View>

                    </View>
                    <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                            <Text style={[styles?.ultraBold, { paddingBottom: "20", fontWeight: "bold" }]}>Cost distribution %</Text>
                            {visualDonut !== undefined && (
                                <View style={{ display: "flex", alignItems: "center", }}>
                                    <Image style={{ margin: "0 0 0 20", width: technology_name === Technology_Name?.POLYMER_ESTIMATION ? 252.75 : 270, height: technology_name === Technology_Name?.POLYMER_ESTIMATION ? 126 : 135 }} src={visualDonut} />
                                </View>
                            )}
                        </View>
                        {
                            technology_name === Technology_Name?.POLYMER_ESTIMATION && (
                                <View style={{ display: "flex", flexDirection: "column", marginTop: 70, }}>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 10, height: 10, backgroundColor: "#556679", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }} >System:</Text>
                                        <Text style={{ color: "#000", fontSize: 10, fontWeight: '800' }} >{formObj?.system_cost_per_part?.value ? `${formObj?.system_cost_per_part?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 10, height: 10, backgroundColor: "#7daebc", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }} >Material:</Text>
                                        <Text style={{ color: "#000", fontSize: 10, fontWeight: '800' }} >{formObj?.material_cost_per_part?.value ? `${formObj?.material_cost_per_part?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 10, height: 10, backgroundColor: "#9ea6b4", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }} >Post Processing:</Text>
                                        <Text style={{ color: "#000", fontSize: 10, fontWeight: '800' }} >{formObj?.post_processing_cost_per_part?.value ? `${formObj?.post_processing_cost_per_part?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 10, height: 10, backgroundColor: "#fff", marginRight: 2 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }} >Total Cost Per Part:</Text>
                                        <Text style={{ color: "#000", fontSize: 10, fontWeight: '800' }} >{formObj?.total_cost_per_part?.value ? `${formObj?.total_cost_per_part?.value.toFixed(2)} ${currency}` : 0}</Text>
                                    </View>
                                </View>
                            )
                        }
                    </View>

                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: "0 5% ", alignItems: "center", marginBottom: 20, width: typeDocument === 0 ? "100%" : "95%" }}>
                    <View style={[styles.powderContainer, styles.w_50, { marginRight: technology_name !== Technology_Name?.POLYMER_ESTIMATION ? "37px" : 0, borderWidth: 1, borderColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]}>
                        <View style={[styles.powderTableTop, { borderColor: "#156481", borderWidth: 1, flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, }]}>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>
                                System information
                            </Text>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>
                                {machineName ? `${machineName} ` : 0}

                            </Text>

                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481" }}>
                            <Text style={[styles.inputHeading]}>
                                Initial investment
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.initial_investment?.value ? `${formObj?.initial_investment?.value} ${currency}` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481" }}>
                            <Text style={[styles.inputHeading]}>
                                Recurring Expense
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.recurring_expense?.value ? `${formObj?.recurring_expense?.value} ${currency}/year` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481" }}>
                            <Text style={[styles.inputHeading]}>
                                Depreciation period
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.depreciation_period?.value ? `${formObj?.depreciation_period?.value} year` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481" }}>
                            <Text style={[styles.inputHeading]}>
                                Interest rate
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.interest_rate?.value ? `${formObj?.interest_rate?.value} %` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481" }}>
                            <Text style={[styles.inputHeading]}>
                                Utilization
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.utilization?.value ? `${formObj?.utilization?.value} hours/year` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                Capex per year
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.capex_per_year?.value ? `${formObj?.capex_per_year?.value} ${currency}/year` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                            <Text style={[styles.inputHeading]}>
                                System cost per hour
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.system_cost_per_hour?.value ? `${formObj?.system_cost_per_hour?.value} ${currency}/year` : 0}
                            </Text>
                        </View>

                    </View>
                    {
                        technology_name !== Technology_Name?.POLYMER_ESTIMATION ? (
                            <View style={{ width: "50%", paddingLeft: "5%", display: "flex", alignSelf: "flex-start" }}>
                                <View style={{ display: "flex", flexDirection: "column", marginTop: 70, }}>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 12, height: 12, backgroundColor: "#556679", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >System:</Text>
                                        <Text style={{ color: "#000", fontSize: 12, fontWeight: '800' }} >{formObj?.system_cost_per_cm3?.value ? `${formObj?.system_cost_per_cm3?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 12, height: 12, backgroundColor: "#7daebc", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >Recurring:</Text>
                                        <Text style={{ color: "#000", fontSize: 12, fontWeight: '800' }} >{formObj?.recurring_cost_per_cm3?.value ? `${formObj?.recurring_cost_per_cm3?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 12, height: 12, backgroundColor: "#777777", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >Material:</Text>
                                        <Text style={{ color: "#000", fontSize: 12, fontWeight: '800' }} >{formObj?.material_cost_per_cm3?.value ? `${formObj?.material_cost_per_cm3?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 12, height: 12, backgroundColor: "#0095C8", marginRight: 5 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >Consumables:</Text>
                                        <Text style={{ color: "#000", fontSize: 12, fontWeight: '800' }} >{formObj?.consumable_cost_per_cm3?.value ? `${formObj?.consumable_cost_per_cm3?.value} ${currency}` : 0}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                                        <View style={{ width: 12, height: 12, backgroundColor: "#fff", marginRight: 2 }}>
                                        </View>
                                        <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >Total AM Cost per cm3:</Text>
                                        <Text style={{ color: "#000", fontSize: 12, fontWeight: '800' }} >{formObj?.total_am_cost_cm3?.value ? `${formObj?.total_am_cost_cm3?.value.toFixed(2)} ${currency}` : 0}</Text>
                                    </View>

                                    <View style={{ display: "flex", flexDirection: "row" }}>

                                        {
                                            costOfCo2pp ? <>
                                                <View style={{ width: 12, height: 12, backgroundColor: "#fff", marginRight: 5 }}>
                                                </View>
                                                <Text style={{ color: "#000", fontSize: 12, marginRight: 5 }} >Carbon Pricing: </Text>
                                                <Text style={{ color: "#000", fontSize: 12, fontWeight: 'bold' }} >{costOfCo2pp ? `${costOfCo2pp} ${currency}` : 0}</Text>

                                            </> : <></>
                                        }
                                    </View>
                                </View>
                            </View>
                        )

                            :
                            (
                                <View style={{ width: "50%", marginLeft: "5%" }}>
                                    {
                                        machineImage &&
                                        <Image style={{ margin: "0 0 0 20", width: '250', height: 250 }} src={machineImage} />

                                    }

                                </View>
                            )
                    }

                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: "0 5% ", alignItems: "center", width: typeDocument === 0 ? "100%" : "95%" }}>
                    <View style={[styles.powderContainer, styles.w_50, typeDocument === 0 ? { marginRight: "5%", borderWidth: 1, borderColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 } : { marginRight: "3%", borderWidth: 1, borderColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]}>
                        <View style={[styles.powderTableTop, { borderBottomWidth: 1, borderBottomColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, }]}>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Job details</Text>

                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                Nesting density
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.fill_rate?.value ? `${formObj?.fill_rate?.value} %` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                Parts per job
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.no_of_parts?.value ? formObj?.no_of_parts?.value : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                Total job volume
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.build_volume?.value ? `${formObj?.build_volume?.value} cm3` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}> Job time </Text>
                            <Text style={[styles.inputHeading]}>{(formObj?.setup_time?.value ? formObj?.setup_time?.value : 0) + (formObj?.build_time?.value ? formObj?.build_time?.value : 0) + (formObj?.unpacking_time_per_job?.value ? formObj?.unpacking_time_per_job?.value : 0) + (formObj?.cool_down_time_in_system?.value ? formObj?.cool_down_time_in_system?.value : 0)} h/min</Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                Parameter
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {parameterName ? `${parameterName} ` : 0}

                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                            <Text style={[styles.inputHeading]}>
                                Additional material losses
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.additional_material_losses?.value ? `${formObj?.additional_material_losses?.value} %` : 0}
                            </Text>
                        </View>
                    </View>
                    {/* <View style={{ marginRight: "5%" }}> */}
                    <View style={[styles.powderContainer, styles.w_50, { borderWidth: 1, borderColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]}>
                        <View style={[styles.powderTableTop, { borderColor: "#156481", borderWidth: 1, flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, borderBottomColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, }]}>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Overhead</Text>

                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>
                                {formObj?.cpp_operators?.value !== undefined && formObj?.cpp_floor_space?.value !== undefined && formObj?.cpp_overhead?.value !== undefined ? `${(formObj?.cpp_operators?.value + formObj?.cpp_floor_space?.value + formObj?.cpp_overhead?.value).toFixed(2)} ` : 0}
                            </Text>

                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                CPP operator
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.cpp_operators?.value ? `${formObj?.cpp_operators?.value} ` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                            <Text style={[styles.inputHeading]}>
                                CPP floor space
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.cpp_floor_space?.value ? `${formObj?.cpp_floor_space?.value} ${currency} ` : 0}
                            </Text>
                        </View>
                        <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                            <Text style={[styles.inputHeading]}>
                                Cpp overheads
                            </Text>
                            <Text style={[styles.inputHeading]}>
                                {formObj?.cpp_overhead?.value ? `${formObj?.cpp_overhead?.value} ${currency}` : 0}
                            </Text>
                        </View>


                    </View>
                    {/* </View> */}
                </View>

                {/* <View style={{ position: "fixed", marginTop: "20vh", display: "flex", justifyContent: "center", alignItems: "center", width: "95%", }}>
                    <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text>
                </View>
                <View style={{ position: "absolute", right: "20px", bottom: "2px" }}>
                    <Text >page 1 of 4</Text>
                </View> */}
            </View>

            <View style={[styles.reportsSection,]} >
                {/* <View style={[styles.powderContainer, { marginBottom: 30, borderColor: "#156481", borderWidth: 1, marginTop: 50 }]}>
                    <View style={[styles.powderTableTop, {
                        borderColor: "#156481", borderWidth: 1, justifyContent: "space-between", flexDirection: "row", borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                    }]}>
                        <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Post processing</Text>
                        <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>{
                            formObj?.post_processing_cost_per_part?.value
                                ?
                                <>{`${formObj?.post_processing_cost_per_part?.value} ${currency}`}</>
                                :
                                "Not Available"
                        }</Text>

                    </View>
                </View> */}
                <View style={[styles.powderContainer, { marginBottom: 30, borderColor: "#156481", borderWidth: 1, }]}>
                    <View style={[styles.powderTableTop, { flexDirection: "row", justifyContent: "space-between" }]}>
                        <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Cost per part</Text>
                        <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>{formObj?.total_am_cpp?.value ? `${(formObj?.total_am_cpp?.value).toFixed(2)} ${currency}` : "Not Available"}</Text>

                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                        <Text style={[styles.inputHeading]}>
                            System cost per part
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.system_cost_per_part?.value ? `${formObj?.system_cost_per_part?.value} ${currency}` : 0}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                        <Text style={[styles.inputHeading]}>
                            Recurring cost per part
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.recurring_cost_per_part?.value ? `${formObj?.recurring_cost_per_part?.value} ${currency}` : 0}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                        <Text style={[styles.inputHeading]}>
                            Material cost per part
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.material_cost_per_part?.value ? `${formObj?.material_cost_per_part?.value} ${currency}` : 0}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1 }}>
                        <Text style={[styles.inputHeading]}>
                            Consumables cost per part
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.cpp_consumables?.value ? `${formObj?.cpp_consumables?.value} ${currency}` : 0}
                        </Text>
                    </View>

                    <Co2InPdf costOfCo2pp={costOfCo2pp} styles={styles} currency={currency} />


                </View>
                {cppData !== undefined &&
                    cppData.length > 1 &&
                    <View style={[styles.powderContainer, { borderColor: "#156481", borderWidth: 1, width: typeDocument === 0 ? "100%" : "95%" }]} break>
                        <View style={styles.powderTableTop}>
                            <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>Multiple parts</Text>
                        </View>
                        {
                            cppData !== undefined &&
                            cppData.map((data, index) => {
                                return (
                                    <View>
                                        {
                                            1 === cppData.length ?
                                                (<View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                    <Text style={[styles.inputHeading]}>
                                                        {`Cost Per Part ${index + 1}`}
                                                    </Text>
                                                    <Text style={[styles.inputHeading]}>
                                                        {data.value} {currency}
                                                    </Text>
                                                </View>)
                                                :
                                                (
                                                    <View style={index === cppData.length - 1 ? { display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 } : { display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderColor: "#156481", borderWidth: 1, }}>
                                                        <Text style={[styles.inputHeading]}>
                                                            {`Cost Per Part ${index + 1}`}
                                                        </Text>
                                                        <Text style={[styles.inputHeading]}>
                                                            {data.value} {currency}
                                                        </Text>
                                                    </View>
                                                )
                                        }

                                    </View>
                                )
                            })
                        }

                    </View>}

            </View>
            {/* <View style={{ position: "fixed", marginTop: "20vh", display: "flex", justifyContent: "center", alignItems: "center", width: "95%", }}>
                <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text>
                <View style={{ position: "absolute", right: "2px", bottom: "2px" }}>
                    <Text >page 2 of 4</Text>
                </View>
            </View> */}
            <View style={[styles.reportsSection, { height: "95%", width: typeDocument === 0 ? "100%" : "95%" }]} break={!(cppData !== undefined && cppData.length > 1)}>
                <View style={[styles.powderContainer, { borderWidth: 1, borderColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]} >
                    <View style={[styles.powderTableTop, { borderBottomWidth: 1, borderBottomColor: "#156481", borderTopLeftRadius: 10, borderTopRightRadius: 10, }]}>
                        <Text style={typeDocument === 0 ? [styles.powderTableHeading] : [styles.powderTableHeading2]}>
                            Production scenarios
                        </Text>

                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Builds per week
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {totalJobs}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Working weeks per year
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            46
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Jobs per year
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {Math.trunc((46 * totalJobs).toFixed(2))}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Throughput per year
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {Math.trunc((46 * totalJobs) * formObj.no_of_parts?.value)}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Turnaround time
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {`${singleJobTime} h`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Operators needed per machine
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.number_of_operators?.value ? formObj?.number_of_operators?.value : 1}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", }}>
                        <Text style={[styles.inputHeading]}>
                            Number of shifts
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {workingHours ? "1" : "3"}
                        </Text>
                    </View>
                    <View style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'row', padding: "10 20", borderBottomWidth: 1, borderBottomColor: "#156481", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                        <Text style={[styles.inputHeading]}>
                            Operators's time needed per job
                        </Text>
                        <Text style={[styles.inputHeading]}>
                            {formObj?.operators_time_needed_per_job?.value ? formObj?.operators_time_needed_per_job?.value : +formObj?.unpacking_time_per_job?.value + +formObj?.setup_time?.value} h
                        </Text>
                    </View>

                </View>
                <View style={{
                    display: "flex", justifyContent: "center", alignItems: "center", width: '100%', marginTop: 45,
                }} >
                    <Text style={[styles.partsHeading]} >Production scheduling</Text>
                </View>
                <View style={{ width: "800px", hegiht: "800px", maxWidth: "800px", maxHeight: "800px", marginTop: "20px" }}  >
                    <Image allowDangerousPaths style={{ width: "800px", hegiht: "800px", maxWidth: "800px", maxHeight: "800px" }} src={psImage} alt="check" />

                </View>

                {
                    isHotSpotAvailable ? <></> :
                        <View style={[styles.powderContainer, typeDocument === 0 ? { border: 1, borderColor: "#000", paddingBottom: 0, width: "95%", borderTopLeftRadius: 10, borderTopRightRadius: 10, } : { border: 1, borderColor: "#000", paddingBottom: 0, width: "95%", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginRight: "5%" }]}>
                            <View style={[styles.powderTableTop, { justifyContent: "space-between", padding: 0, backgroundColor: "#00677d", flexDirection: "row" }]}>
                                {
                                    hotspotArray.map((data, index) => {

                                        return (
                                            <Text key={index} style={{
                                                fontStyle: "normal",
                                                fontWeight: "semibold",
                                                fontSize: 10,
                                                textAlign: "left",
                                                textTransform: "capitalize",
                                                color: typeDocument === 0 ? "#fff" : "#000",
                                                padding: "10 10",
                                                borderTopLeftRadius: `${data.heading === "CO2e Powder" ? 10 : 0}`,
                                                borderTopRightRadius: `${data.heading === "CO2e Logistics" ? 10 : 0}`,
                                                // borderColor: "#000",
                                                // borderWidth: 1,
                                                backgroundColor: data.heading.includes(hightlightKey) && hightlightKey !== "" ? "#82D1E6" : "",

                                                width: "32%",
                                            }}>
                                                {data.heading}
                                            </Text>
                                        )
                                    })
                                }
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", }}>
                                {
                                    hotspotArray.map((data, index) => {
                                        const a = data.description
                                        const b = a.replace(",", "c")

                                        return (
                                            <View key={index} style={{
                                                textAlign: "left",
                                                // textTransform: "capitalize",
                                                // color: "#000",
                                                width: "33%",
                                                fontSize: 2,
                                                padding: "0 10",
                                                // borderLeftColor: "#000",
                                                // borderLeftWidth: index === 0 ? 0 : 1,
                                                paddingBottom: 10
                                            }}>
                                                <Html stylesheet={styleSheet2} >{b}</Html>
                                            </View>
                                        )
                                    })
                                }
                            </View>

                        </View>

                }

                <Text style={[styles.divTableCell1, { width: "95%", color: "#000", paddingTop: "20px" }]}>
                    Please note that the EOS Cost and Carbon Calculator provides only rough estimates.  EOS assumes no liability for the information from this tool. For precise and reliable values, please consult with Additive Minds at EOS. Please see the Data Matrix to understand the basis for the calculation of the EOS Cost & Carbon Calculator.
                </Text>
            </View>

            {/* <View style={[styles.powderContainer, { alignItems: "center", marginLeft: "2%" }]}>
                <View style={[styles.powderTableTopTwo, { width: "818", alignItems: "center", }]}>
                    <View style={[styles.powderHeadingTwo, { display: "flex", flexDirection: "row", }]}>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116, }}>
                            <Text>
                                Mon
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116, }}>
                            <Text>
                                Tue
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116 }}>
                            <Text>
                                Wed
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116 }}>
                            <Text>
                                Thu
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116 }}>
                            <Text>
                                Fri
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116 }}>
                            <Text>
                                Sat
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10", flexDirection: "column", width: 116 }}>
                            <Text>
                                Sun
                            </Text>
                            <Text>
                                09:00am-05:00pm
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row", marginBottom: "10", width: "818", }}>
                    {
                        jobsPerWeek.map((element, index) => {
                            console.log("PDF ELEMENT", element);
                            return (
                                <View style={{}}>
                                    <View key={index} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: 120,
                                        paddingTop: 20,
                                        position: "relative"
                                    }}>
                                        <View style={{ position: "absolute", backgroundColor: '#f5f5dc', height: 80, width: 38, left: "44" }}>
                                            <Text style={{ opacity: 0 }} >12</Text>
                                        </View>
                                        {
                                            element.map((dataInstance, index) => {
                                                console.log("PDF DATAINSTANCE", dataInstance.percentage);

                                                return (
                                                    <View
                                                        key={index}
                                                        style={{
                                                            backgroundColor: dataInstance.color,
                                                            width: `${dataInstance.percentage}%`,
                                                            height: "40"
                                                        }}
                                                    >
                                                        <Text style={{ opacity: 0 }} >{dataInstance.value}</Text>
                                                    </View>

                                                )
                                            })
                                        }

                                    </View>

                                </View>
                            )
                        })
                    }

                </View>

            </View> */}




            <View style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", }}  >

                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }} >
                    <Image allowDangerousPaths style={{ width: "100%", }} src="../assets/reportV3V4.png" alt="check" />
                    {/* <Text>For more information or in case of any question, please contact Additive Minds or EOS sales team </Text> */}
                </View>
                {/* <View style={{ position: "absolute", right: "20px", bottom: "2px" }}>
                    <Text >page 4 of 4</Text>
                </View> */}
            </View>

            {/* <View fixed>
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ marginRight: 20 }}>For more information or in case of any question, please contact Additive Minds or EOS sales team</Text>
                    <Text render={({ pageNumber, totalPages }) => (
                        `${pageNumber} of ${totalPages}`
                    )} />

                </View>
            </View> */}
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `For more information or in case of any question, please contact Additive Minds or EOS sales team            page ${pageNumber} of ${totalPages}`
            )} fixed />

        </Page>
    </Document >
);




function PdfGenerator({ formFields, donutImage, typeDocument, pdfName, cppData, costOfCo2pp, currency, visualDonut, machineName, materialName, parameterName, jobsPerWeek, totalJobs, singleJobTime, machineImage, psImage, initialDocumentState, cppSum, workingHours, hotspotArray, highestIndex, hightlightKey, isHotSpotAvailable, technology_name }) {
    const [formFieldsObj, setFormFieldsObj] = React.useState()
    let temp = true;
    console.log("machine image in pdf", machineImage);
    React.useEffect(() => {
        // console.log('formFields', formFields)
        setFormFieldsObj(formFields)
    }, [formFields])


    return (
        <div>
            {/* <div id="faq-header"> */}
            {(formFieldsObj) && (

                <BlobProvider document={<Doc formObj={formFieldsObj} donutImage={donutImage} typeDocument={typeDocument} pdfName={pdfName} cppData={cppData} costOfCo2pp={costOfCo2pp} currency={currency} visualDonut={visualDonut} machineName={machineName} materialName={materialName} parameterName={parameterName} jobsPerWeek={jobsPerWeek} totalJobs={totalJobs} singleJobTime={singleJobTime} machineImage={machineImage} psImage={psImage} cppSum={cppSum} workingHours={workingHours} hotspotArray={hotspotArray} highestIndex={highestIndex} hightlightKey={hightlightKey} isHotSpotAvailable={isHotSpotAvailable} technology_name={technology_name} />}>
                    {({ blob, url, loading, error }) => {
                        // Do whatever you need with blob here
                        // console.log("data:image/svg+xml;base64," + donutImage);
                        // console.log("Blob", blob);
                        // console.log("PDF JOBS", jobsPerWeek);
                        // console.log("highestIndex", highestIndex);
                        if (!loading && temp) {
                            temp = false

                            if (typeDocument === 0) {
                                toast.info(NOTIFICATION_MESSAGES.PDF_WAIT_NOTIFY)

                                window.open(url, '_blank');
                                initialDocumentState()
                            } else if (typeDocument === 1) {
                                let data = new FormData();
                                data.append('file', blob)

                                toast.info(NOTIFICATION_MESSAGES.WAIT_NOTIFY)
                                axios.post(`${config.serviceUrl}client-data`, data,
                                    {
                                        headers: {
                                            'accept': 'application/json',
                                            'Accept-Language': 'en-US,en;q=0.8',
                                            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                                        }
                                    })
                                    .then(function (response) {
                                        if (response.data) {
                                            toast.success(NOTIFICATION_MESSAGES.SUCCESS_NOTIFY)
                                            saveAs(`${config.serviceUrl}${response.data.data}`, `${pdfName}.docx`)
                                            initialDocumentState()
                                        }

                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })
                            }





                        }
                        return ''
                    }}
                </BlobProvider>


            )}


        </div>

    )
}

export default PdfGenerator;